import { parseSoapResponse } from 'api/GetLookupTables';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

const CustomerSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairCustomers');

      const formattedData = test123?.map((item) => item?.['b:AccountNum']);

      if (lookupTables) {
        setData(formattedData);
      }
    };

    fetchData();
  }, [lookupTables]);

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="CustomerAccountNumber" label="Customer ID" placeholder="Customer ID" />;
};

export const CustomerSelect = React.memo(CustomerSelectComponent);
