export function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const parseSoapResponse = (xmlResponse: any, table: string, isDim = false) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

  if (isDim) {
    const resp = xmlDoc.getElementsByTagName('a:ImportItemSearchResult');

    const formattedResults = Array.from(resp).map((item) => {
      return Array.from(item.childNodes)
        .map((node) => ({
          [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]:
            node.nodeName === 'a:Dimension'
              ? Array.from(node.childNodes)
                  .map((node) => ({ [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent }))
                  .reduce((acc, obj) => ({ ...acc, ...obj }), {})
              : node.textContent
        }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    });

    return formattedResults;
  }

  const namespaces = {
    s: 'http://schemas.xmlsoap.org/soap/envelope/',
    a: 'http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair.Result'
  };

  const inventDimCombinations = xmlDoc.getElementsByTagNameNS(namespaces.a, table)[0];

  if (inventDimCombinations && inventDimCombinations.childNodes.length > 0) {
    // Loop through child nodes and extract data
    const items = inventDimCombinations.childNodes;

    const combos = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      let testArr: any = {};

      item?.childNodes?.forEach((node) => (testArr = { ...testArr, [node.nodeName]: node.textContent }));

      // Process each item as needed
      combos.push(testArr);
    }

    return combos;
  } else {
    console.warn('InventDim data is empty.');
  }
};

export const getLookupTables = async (dataAreaId = 'MDSI'): Promise<{ textResponse: string; dataAreaId: string }> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetLookupTables'
    },
    body: `
                  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
      <soapenv:Header/>
      <soapenv:Body>
        <tns:GetLookupTables>
          <tns:dataAreaId>${dataAreaId}</tns:dataAreaId>
        </tns:GetLookupTables>
      </soapenv:Body>
    </soapenv:Envelope>
                `
  });

  const textResponse = await resp.text();

  return { textResponse, dataAreaId };
  //   const parsedResp = parseSoapResponse(textResponse, table ?? '');
};
