import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { AssignSalesIdToLines } from 'components/atoms/AssignSalesIdToLines';
import { RepairOrderToAssignSoNumber, SOBillingTable } from 'components/molecules/SOBillingTable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SOBilling = (): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<RepairOrderToAssignSoNumber[]>([]);

  const nav = useNavigate();

  return (
    <>
      <Row justify={'space-between'} align={'middle'} style={{ marginBottom: 12, width: '100%' }}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
          SO Billing
        </Typography.Title>
        <Space>
          <Button onClick={() => nav('/')} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <AssignSalesIdToLines selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Space>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={24}>
          <SOBillingTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Col>
      </Row>
    </>
  );
};
