import { Select } from 'antd';
import { getRepairLineItemStatusesByCustomer } from 'api/GetItemStatusesByCustomer';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/store';

type Props = {
  record: RepairOrderToBePicked;
  setForm?: React.Dispatch<React.SetStateAction<any>>;
};

const ItemStatusByCustomerSelectComponent: FC<Props> = ({ record, setForm }) => {
  const [data, setData] = useState<any[]>([]);
  const { selectedDataAreaId } = useSelector((state: ReduxState) => state.app);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await getRepairLineItemStatusesByCustomer({ custAccount: record.customerAccountNum, repairTypeId: record.repairType, dataAreaId: selectedDataAreaId });

      const data = resp?.map((item) => {
        return { itemStatusId: item?.itemStatusId, conditionFinished: item?.conditionFinished, dispositionFinished: item?.dispositionFinished };
      });

      setData(data);
      setLoading(false);
    };

    fetchData();
  }, [record]);

  return (
    <Select
      loading={loading}
      onChange={(e) => {
        const conditionFinished = data.find((item) => item.itemStatusId === e)?.conditionFinished ?? '';
        const dispositionFinished = data.find((item) => item.itemStatusId === e)?.dispositionFinished ?? '';

        setForm?.((prev: any) => ({ ...prev, itemStatusId: e, conditionFinished, dispositionFinished }));
      }}
      style={{ width: '100%' }}
      options={data?.map((item) => ({ label: item.itemStatusId, value: item.itemStatusId }))}
      placeholder="Item Status"
    />
  );
};

export const ItemStatusByCustomerSelect = React.memo(ItemStatusByCustomerSelectComponent);
