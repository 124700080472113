import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';

export function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const getRepairLineHistory = async ({ record, dataAreaId }: { record: Partial<RepairOrderToBePicked>; dataAreaId: string }): Promise<any> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetFaultCodeHistory'
    },
    body: `
                      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
          <soapenv:Header/>
          <soapenv:Body>
            <tns:GetFaultCodeHistory>
              <tns:dataAreaId>${dataAreaId}</tns:dataAreaId>
              <tns:repairLineRecId>${record.recId ? +record.recId : 0}</tns:repairLineRecId>
              <tns:repairorderid>${record.repairOrderId}</tns:repairorderid>
            </tns:GetFaultCodeHistory>
          </soapenv:Body>
        </soapenv:Envelope>
                    `
  });
  const respResolution = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetResolutionHistory'
    },
    body: `
                      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
          <soapenv:Header/>
          <soapenv:Body>
            <tns:GetResolutionHistory>
               <tns:dataAreaId>${dataAreaId}</tns:dataAreaId>
              <tns:repairLineRecId>${record.recId ? +record.recId : 0}</tns:repairLineRecId>
              <tns:repairorderid>${record.repairOrderId}</tns:repairorderid>
            </tns:GetResolutionHistory>
          </soapenv:Body>
        </soapenv:Envelope>
                    `
  });

  const textResponse = await resp.text();
  const textResponseResolution = await respResolution.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(textResponse, 'text/xml');
  const xmlDocResolution = parser.parseFromString(textResponseResolution, 'text/xml');

  const parsedCollectionResolution = xmlDocResolution.getElementsByTagName('a:RepairResolutionCode');
  const parsedCollection = xmlDoc.getElementsByTagName('a:RepairFaultCode');
  const formattedData = Array.from(parsedCollection).map((item) => {
    return Array.from(item.childNodes)
      .map((node) => ({
        [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]:
          node.nodeName === 'a:Dimension'
            ? Array.from(node.childNodes)
                .map((node) => ({ [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {})
            : node.textContent
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  });
  const formattedDataResolution = Array.from(parsedCollectionResolution).map((item) => {
    return Array.from(item.childNodes)
      .map((node) => ({
        [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]:
          node.nodeName === 'a:Dimension'
            ? Array.from(node.childNodes)
                .map((node) => ({ [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {})
            : node.textContent
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  });

  return { faultHistory: formattedData, resolutionHistory: formattedDataResolution };
};
