export const setRepairLineTech = async ({
  dataAreaId,
  repairLineRecIds,
  technicianId,
  userName
}: {
  repairLineRecIds: number[];
  dataAreaId: string;
  technicianId: string;
  userName: string;
}): Promise<string> => {
  const payload = `
       <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <SetRepairLineTechMultipleLines xmlns="http://tempuri.org/">
      <repairLineRecIds xmlns:d4p1="http://schemas.microsoft.com/2003/10/Serialization/Arrays" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
      ${repairLineRecIds.map((id) => `<d4p1:long>${id}</d4p1:long>`).join('')}</repairLineRecIds>
      <repairTechnicianId>${technicianId}</repairTechnicianId>
      <user xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:DataAreaId>${dataAreaId}</d4p1:DataAreaId>
        <d4p1:DefaultInventLocationId></d4p1:DefaultInventLocationId>
        <d4p1:DefaultPrinterName></d4p1:DefaultPrinterName>
        <d4p1:Groups xmlns:d5p1="http://schemas.microsoft.com/2003/10/Serialization/Arrays" />
        <d4p1:Password></d4p1:Password>
        <d4p1:UserName>${userName}</d4p1:UserName>
      </user>
      <dataAreaId>${dataAreaId}</dataAreaId>
    </SetRepairLineTechMultipleLines>
  </s:Body>
</s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/SetRepairLineTechMultipleLines'
    },
    body: payload
  });

  const textResponse = await resp.text();

  return textResponse;
};
