/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Table, TableColumnType } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import { parseSoapResponse } from 'api/GetLookupTables';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { FancySelect } from 'components/ui/filters/FancySelect';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/store';
type SearchType = {
  itemId: string;
  inventSerialId: string;
  ownerId: string;
  conditionId: string;
  dispositionId: string;
};

type Props = {
  handleSubmit: ({ callback }: { callback?: () => void }) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
};

export const parseSoapResponseTest = (xmlResponse: any, isDataAreaId = false) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

  if (isDataAreaId) {
    const inventDimCombinations = xmlDoc.getElementsByTagName('a:string');

    const test = Array.from(inventDimCombinations).map((node) => {
      return node.textContent;
    });

    return test;
  }

  const namespaces = {
    s: 'http://schemas.xmlsoap.org/soap/envelope/',
    a: 'http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair.Result'
  };

  const inventDimCombinations = xmlDoc.getElementsByTagNameNS(namespaces.a, 'InventDimCombinations')[0];

  if (inventDimCombinations && inventDimCombinations.childNodes.length > 0) {
    // Loop through child nodes and extract data
    const items = inventDimCombinations.childNodes;

    const combos = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      let testArr: any = {};

      item?.childNodes?.forEach((node) => (testArr = { ...testArr, [node.nodeName]: node.textContent }));

      // Process each item as needed
      combos.push(testArr);
    }

    return combos;
  } else {
    console.warn('InventDim data is empty.');
  }
};

const LineItemSearchComponent: FC<Props> = ({ setSelectedRows }) => {
  const [data, setData] = useState<any[]>([]);
  const { lookupTables, selectedDataAreaId } = useAppSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [isDimResultLoading, setIsDimResultLoading] = useState(false);
  const [dimResults, setDimResults] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [selectedCombination, setSelectedCombination] = useState<
    | {
        ConfigId: string;
        InventSizeId: string;
        InventColorId: string;
      }
    | undefined
  >(undefined);

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex as keyof any]
        ?.toString()
        ?.toLowerCase()
        .includes((value as string)?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text)
  });

  const columns: ColumnsType<SearchType> = [
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
      ...getColumnSearchProps('itemId')
    },
    {
      title: 'Serial Number',
      dataIndex: 'inventSerialId',
      key: 'inventSerialId',
      ...getColumnSearchProps('inventSerialId')
    },
    {
      title: 'Owner',
      render: (text: string, record: any) => <div>{record.dimension.configId}</div>
    },
    {
      title: 'Condition',
      render: (text: string, record: any) => <div>{record.dimension.inventSizeId}</div>
    },
    {
      title: 'Disposition',
      render: (text: string, record: any) => <div>{record.dimension.inventColorId}</div>
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/xml',
          SOAPAction: 'http://tempuri.org/IRepairModule/GetLookupTables'
        },
        body: `
                <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tns:GetLookupTables>
        <tns:dataAreaId>${selectedDataAreaId}</tns:dataAreaId>
      </tns:GetLookupTables>
    </soapenv:Body>
  </soapenv:Envelope>
              `
      });

      const textResponse = await resp.text();
      const parsedResponse = parseSoapResponseTest(textResponse);

      if (parsedResponse) {
        setLoading(false);

        setData(parsedResponse as any);
      }
      setLoading(false);
    };
    const fetchInventory = async () => {
      setIsDimResultLoading(true);
      const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/xml',
          SOAPAction: 'http://tempuri.org/IRepairModule/SearchItemsWithInventory'
        },
        body: `
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <SearchItemsWithInventory xmlns="http://tempuri.org/">
      <dimCombination xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:ConfigId>${selectedCombination?.ConfigId.trim()}</d4p1:ConfigId>
        <d4p1:InventColorId>${selectedCombination?.InventSizeId.trim()}</d4p1:InventColorId>
        <d4p1:InventSizeId>${selectedCombination?.InventColorId.trim()}</d4p1:InventSizeId>
      </dimCombination>
      <dataAreaId>${selectedDataAreaId.trim()}</dataAreaId>
    </SearchItemsWithInventory>
  </s:Body>
</s:Envelope>
              `
      });

      const textResponse = await resp.text();

      const formattedData = parseSoapResponse(textResponse, 'SearchItemsWithInventoryResult', true);

      if (formattedData) {
        setIsDimResultLoading(false);

        const uniqueArray = formattedData.filter((item, index, self) => index === self.findIndex((t) => t.itemId === item.itemId && t.inventSerialId === item.inventSerialId));

        setDimResults(uniqueArray);
      } else {
        setIsDimResultLoading(false);
      }
    };

    if (selectedCombination) {
      fetchInventory();
    } else if (selectedDataAreaId && !selectedCombination) {
      if (selectedDataAreaId === 'MDSI') {
        const parsedResponse = parseSoapResponseTest(lookupTables);

        setData(parsedResponse as any);
      } else fetchData();
    }
  }, [selectedDataAreaId, selectedCombination]);

  const rowSelection: TableProps<SearchType>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: SearchType[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (row: SearchType) => ({
      name: `${row.itemId}-${row.inventSerialId ?? 'n/a'}`
    })
  };

  return (
    <Space direction="vertical" size={10} style={{ width: '100%' }}>
      <Space style={{ marginBottom: 0 }}>
        <FancySelect
          style={{ width: 300 }}
          isDisabled={!selectedDataAreaId}
          loading={loading}
          width={325}
          value={selectedCombination ? `${selectedCombination?.ConfigId} - ${selectedCombination?.InventColorId} - ${selectedCombination?.InventSizeId}` : ''}
          disabled={!selectedDataAreaId}
          onChange={(e) => {
            if (!e) return setSelectedCombination(undefined);
            setSelectedCombination({
              ConfigId: e.split(' - ')[0],
              InventColorId: e.split(' - ')[1],
              InventSizeId: e.split(' - ')[2]
            });
          }}
          options={data?.map((config) => ({
            label: `${config[`b:ConfigId`] ?? ''} - ${config[`b:InventSizeId`] ?? ''} - ${config[`b:InventColorId`] ?? ''}`,
            value: `${config[`b:ConfigId`] ?? ''} - ${config[`b:InventSizeId`] ?? ''} -  ${config[`b:InventColorId`] ?? ''}`
          }))}
          placeholder="Dimension Combination"
        />
      </Space>
      <Table
        loading={{ indicator: <LoaderWithMessage loadingMessage="Loading..." />, spinning: isDimResultLoading }}
        rowKey={(row, getRowKey): string => {
          return `${row.itemId}-${row.inventSerialId ?? 'n/a'}`;
        }}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        dataSource={dimResults}
        columns={columns}
        size="small"
      />
    </Space>
  );
};

export const LineItemSearch = React.memo(LineItemSearchComponent);
