import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { AssignTechButton } from 'components/atoms/AssignTechButton';
import { MoveLinesToPending } from 'components/atoms/MoveLinesToPending';
import { MoveToProcessLines } from 'components/atoms/MoveToProcessLines';
import { LineItemTechQueueTable, RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const TechQ = (): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<RepairOrderToBePicked[]>([]);

  const nav = useNavigate();

  return (
    <>
      <Row justify={'space-between'} align={'middle'} style={{ marginBottom: 12, width: '100%' }}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
          Tech Queue
        </Typography.Title>
        <Space>
          <Button onClick={() => nav('/')} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <AssignTechButton selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
          <MoveLinesToPending selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
          <MoveToProcessLines selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Space>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={24}>
          <LineItemTechQueueTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Col>
      </Row>
    </>
  );
};
