export const markLinesToPicked = async ({ lineRecIds, wmsUserName, dataAreaId }: { lineRecIds: number[]; dataAreaId: string; wmsUserName: string }): Promise<string> => {
  const payload = `
       <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <MarkRepairLinesPicked xmlns="http://tempuri.org/">
      <lineRecIds xmlns:d4p1="http://schemas.microsoft.com/2003/10/Serialization/Arrays" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
      ${lineRecIds.map((id) => `<d4p1:long>${id}</d4p1:long>`).join('')}
      </lineRecIds>
      <wmUsername>${wmsUserName}</wmUsername>
      <dataAreaId>${dataAreaId}</dataAreaId>
    </MarkRepairLinesPicked>
  </s:Body>
</s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/MarkRepairLinesPicked'
    },
    body: payload
  });

  const textResponse = await resp.text();

  return textResponse;
};
