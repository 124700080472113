import { Col, Row, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { getAllLinesByTechQ } from 'api/GetAllLinesByTechQ';
import { ExportTableButton } from 'components/atoms/ExportTableButton';
import { RepairLineHistoryModal } from 'components/atoms/RepairLineHistoryModal';
import { LineItemTechQueueModal } from 'components/atoms/UpdateTechLineModal';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { FancyInput } from 'components/ui/filters/FancyInput';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRefetchLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export type RepairOrderToBePicked = {
  conditionFinished: string;
  configId: string;
  customerAccountNum: string;
  dateStaged: string;
  dispositionFinished: string;
  error: string;
  hoursWorked: string;
  inboundJournalId: string;
  inventColorId: string;
  inventLocationId: string;
  inventLocationIdFinished: string;
  inventLocationIdInProcess: string;
  inventLocationIdStaging: string;
  inventSizeId: string;
  isValidationEnforced: string;
  itemId: string;
  itemStatusId: string;
  orderDate: string;
  picker: string;
  recId: string;
  repairLabId: string;
  repairLabInventLocationId: string;
  repairOrderId: string;
  repairPrice: string;
  repairSerialNumber: string;
  repairStageId: string;
  repairTechnicianId: string;
  repairType: string;
  sLABusinessDays: string;
  salesOrderId: string;
  wMSLocationId: string;
  wmsLocationIdFinished: string;
  wmsLocationIdInProcess: string;
  wmsLocationIdStaging: string;
};

type Props = TableProps & {
  repairTypeId?: string;
  setSelectedRows: React.Dispatch<React.SetStateAction<RepairOrderToBePicked[]>>;
  selectedRows: RepairOrderToBePicked[];
};

export const LineItemTechQueueTable: FC<Props> = ({ setSelectedRows, selectedRows, ...rest }) => {
  const { selectedDataAreaId, refetchLines } = useAppSelector((state) => state.app);
  const [tableData, setTableData] = useState<RepairOrderToBePicked[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [orderKeys, setOrderKeys] = useState<string[]>([]);
  const [filterVals, setFilterVals] = useState({
    repairOrderId: '',
    itemId: '',
    serialNumber: '',
    repairStageId: '',
    customerId: '',
    technicianId: '',
    repairLabId: '',
    repairType: '',
    status: ''
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp: any[] = await getAllLinesByTechQ(selectedDataAreaId ?? 'mdsi');

      const uniqueArray = resp.filter(
        (item, index, self) => index === self.findIndex((t) => t.repairOrderId === item.repairOrderId && t.repairSerialNumber === item.repairSerialNumber && t.itemId === item.itemId)
      );

      const newOrderKeys = uniqueArray.map((item) => `${item.repairOrderId}-${item.repairSerialNumber}-${item.itemId}`);

      const reorderData =
        orderKeys.length > 0
          ? [...uniqueArray].sort((a, b) => orderKeys.indexOf(`${a.repairOrderId}-${a.repairSerialNumber}-${a.itemId}`) - orderKeys.indexOf(`${b.repairOrderId}-${b.repairSerialNumber}-${b.itemId}`))
          : uniqueArray;

      setOrderKeys(newOrderKeys);
      setTableData(reorderData);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDataAreaId]);

  useEffect(() => {
    if (refetchLines) {
      fetchData();
      dispatch(setRefetchLines(false));
    }
  }, [refetchLines]);

  const columns: ColumnsType<Partial<RepairOrderToBePicked>> = [
    {
      title: 'Repair Order ID',
      dataIndex: 'repairOrderId',
      key: 'repairOrderId'
    },
    {
      title: 'Customer Id',
      dataIndex: 'customerAccountNum',
      key: 'customerAccountNum'
    },
    {
      title: 'Repair Stage',
      dataIndex: 'repairStageId',
      key: 'repairStageId'
    },
    {
      title: 'Item Id',
      dataIndex: 'itemId',
      key: 'itemId'
    },
    {
      title: 'Serial Number',
      dataIndex: 'repairSerialNumber',
      key: 'repairSerialNumber'
    },
    {
      title: 'Date Staged',
      dataIndex: 'dateStaged',
      key: 'dateStaged',
      render: (text: string) => moment(text).format('MM/DD/YYYY')
    },
    {
      title: 'Warehouse',
      dataIndex: 'inventLocationId',
      key: 'inventLocationId'
    },
    {
      title: 'Bin Location',
      dataIndex: 'wMSLocationId',
      key: 'wMSLocationId'
    },
    {
      title: 'Repair Lab',
      dataIndex: 'repairLabId',
      key: 'repairLabId'
    },
    {
      title: 'Repair Technician',
      dataIndex: 'repairTechnicianId',
      key: 'repairTechnicianId'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <LineItemTechQueueModal record={record as RepairOrderToBePicked} />
            <RepairLineHistoryModal record={record} />
          </Space>
        );
      }
    }
  ];
  const filteredData = tableData?.filter((item) => {
    return (
      item?.repairOrderId?.toLowerCase().includes(filterVals.repairOrderId?.toLowerCase()) &&
      item?.customerAccountNum?.toLowerCase().includes(filterVals.customerId?.toLowerCase()) &&
      item?.itemId?.toLowerCase().includes(filterVals.itemId?.toLowerCase()) &&
      item?.repairSerialNumber?.toLowerCase().includes(filterVals.serialNumber?.toLowerCase()) &&
      item?.repairStageId?.toLowerCase().includes(filterVals.repairStageId?.toLowerCase()) &&
      item?.repairTechnicianId?.toLowerCase().includes(filterVals.technicianId?.toLowerCase()) &&
      item?.repairLabId?.toLowerCase().includes(filterVals.repairLabId?.toLowerCase())
    );
  });
  const rowSelection: TableProps<RepairOrderToBePicked>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: RepairOrderToBePicked[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (row: RepairOrderToBePicked) => ({
      name: `${row.repairOrderId}-${row.repairSerialNumber}-${row.itemId}`
    }),
    selectedRowKeys: selectedRows.map((row) => `${row.repairOrderId}-${row.repairSerialNumber}-${row.itemId}`)
  };

  return (
    <>
      <Row style={{ background: 'white', padding: 16, margin: 0 }} gutter={[8, 8]}>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairOrderId: '' }));
              setFilterVals((prev) => ({ ...prev, repairOrderId: e.target.value }));
            }}
            placeholder="Repair Order Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, customerId: '' }));
              setFilterVals((prev) => ({ ...prev, customerId: e.target.value }));
            }}
            placeholder="Customer Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, itemId: '' }));
              setFilterVals((prev) => ({ ...prev, itemId: e.target.value }));
            }}
            placeholder="Item Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, serialNumber: '' }));
              setFilterVals((prev) => ({ ...prev, serialNumber: e.target.value }));
            }}
            placeholder="Serial Number"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairStageId: '' }));
              setFilterVals((prev) => ({ ...prev, repairStageId: e.target.value }));
            }}
            placeholder="Repair Stage"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, technicianId: '' }));
              setFilterVals((prev) => ({ ...prev, technicianId: e.target.value }));
            }}
            placeholder="Technician"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairLabId: '' }));
              setFilterVals((prev) => ({ ...prev, repairLabId: e.target.value }));
            }}
            placeholder="Repair Lab"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3} style={{ textAlign: 'right' }}>
          <ExportTableButton data={filteredData} />
        </Col>
      </Row>
      <Table
        dataSource={filteredData}
        virtual
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        loading={{ indicator: <LoaderWithMessage loadingMessage="Loading..." />, spinning: loading }}
        size="small"
        columns={columns}
        rowKey={(record: RepairOrderToBePicked): string => `${record.repairOrderId}-${record.repairSerialNumber}-${record.itemId}`}
        pagination={{ pageSize: 25 }}
        {...(rest as TableProps)}
      />
    </>
  );
};
