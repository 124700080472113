import { Select } from 'antd';
import { parseSoapResponse } from 'api/GetLookupTables';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  isTech?: boolean;
  setForm?: React.Dispatch<
    React.SetStateAction<{
      repairStageId: string;
      technicianId: string;
    }>
  >;
};

const RepairTechSelectComponent: FC<Props> = ({ setForm, isTech = false }): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairTechnicians');

      const formattedData = test123?.map((item) => item?.['b:ID']);

      const sortedData = formattedData?.sort();

      setData(sortedData);
    };

    fetchData();
  }, [lookupTables]);

  if (isTech)
    return (
      <Select
        onChange={(e) => {
          setForm?.((prev) => ({ ...prev, technicianId: e }));
        }}
        allowClear
        showSearch
        style={{ width: '100%' }}
        options={data?.map((item) => ({ label: item, value: item }))}
        placeholder="Select Technician"
      />
    );

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="technician" label="Technician" placeholder="Technician" />;
};

export const RepairTechSelect = React.memo(RepairTechSelectComponent);
