import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

dayjs.extend(utc);

interface Props extends DatePickerProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputDatePicker: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const { xs } = useBreakpoint();

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { handleBlur, setFieldValue, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <DatePicker
              inputReadOnly={xs ? true : false}
              format={'MM/DD/YYYY'}
              value={field.value ? dayjs(field.value) : undefined}
              onChange={(date, dateString) => {
                setFieldValue(fieldName, dateString);
              }}
              onBlur={() => handleBlur(fieldName)}
              style={{ width: '100%' }}
              {...rest}
            />

            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
