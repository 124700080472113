import { Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { RepairsTable } from 'components/organisms/RepairsTable';

export const Home = (): JSX.Element => {
  const { xs } = useBreakpoint();

  return (
    <Space direction="vertical" size={10}>
      <Row justify={'space-between'} align={'middle'}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={xs ? 5 : 4}>
          {'Search AX09 Repairs'}
        </Typography.Title>
      </Row>
      {/* <HomeFilters /> */}
      <RepairsTable />
    </Space>
  );
};
