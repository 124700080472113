import { Col, Row } from 'antd';
import { CurrencyInput } from 'components/atoms/CurrencyInput';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputDatePicker } from 'components/atoms/FieldInputDatePicker';
import { FieldInputNumber } from 'components/atoms/FieldInputNumber';
import { ItemIdSelect } from 'components/atoms/ItemIdSelect';
import { ItemStatusSelect } from 'components/atoms/ItemStatusSelect';
import { RepairStageSelect } from 'components/atoms/RepairStageSelect';
import { RepairTechSelect } from 'components/atoms/RepairTechSelect';
import React, { FC } from 'react';

type Props = {
  repairTypeId?: string;
};

const LineItemFormComponent: FC<Props> = ({ repairTypeId }) => {
  return (
    <Row gutter={[20, 10]}>
      <Col span={12}>
        <ItemIdSelect />
      </Col>
      <Col span={12}>
        <FieldInput fieldName="serialNumber" label="Serial Number" placeholder="Serial Number" />
      </Col>
      <Col span={12}>
        <CurrencyInput fieldName="unitPrice" label="Unit Price" placeholder="Unit Price" />
      </Col>
      <Col span={12}>
        <FieldInputNumber controls={false} fieldName="sla" label="SLA (Days)" placeholder="SLA (Days)" type="number" aria-controls="sla" />
      </Col>
      <Col span={12}>
        <RepairStageSelect />
      </Col>
      <Col span={12}>
        <FieldInputDatePicker fieldName="dateStaged" label="Date Staged" placeholder="Date Staged" />
      </Col>
      <Col span={12}>
        <ItemStatusSelect repaidTypeId={repairTypeId} />
      </Col>
      <Col span={12}>
        <RepairTechSelect />
      </Col>
      <Col span={12}>
        <FieldInput fieldName="salesOrder" label="Sales Order" placeholder="Sales Order" />
      </Col>
    </Row>
  );
};

export const LineItemForm = React.memo(LineItemFormComponent);
