import { InputRef } from 'antd';
import { POReceiptLine } from 'components/molecules/LineItemTable';
import { useFormikContext } from 'formik';
import { FC, useRef } from 'react';
import { FieldInput } from './FieldInput';

type Props = {
  fieldName: string;
  placeholder?: string;
  label: string;
};

export const CurrencyInput: FC<Props> = ({ fieldName, placeholder, label }) => {
  const { setFieldValue, values } = useFormikContext<POReceiptLine>();
  const inputRef = useRef<InputRef | undefined>(null);

  const handleFocus = (): void => {
    if (inputRef.current) {
      const current = inputRef.current.input;

      if (current) {
        const position = current.value.length;

        inputRef?.current?.setSelectionRange(position, position);
        current.setSelectionRange(position, position);
      }
    }
  };

  const handleChange = (e: string): void => {
    if (e === undefined) {
      setFieldValue('unitPrice', '0.00');

      return;
    }
    const input = e.replace(/\D/g, '');

    const length = input.length;
    let newValue;

    if (length === 0) {
      newValue = '0.00';
    } else if (length === 1) {
      newValue = `0.0${input}`;
    } else if (length === 2) {
      newValue = `0.${input}`;
    } else {
      const integerPart = input.slice(0, length - 2);
      const decimalPart = input.slice(length - 2);

      newValue = String(`${Number(integerPart)}.${decimalPart}`);
      setFieldValue('unitPrice', newValue);
    }
  };
  const updateValue = (numericValue: string): string => {
    let integerPart = '';
    let decimalPart = '';

    if (numericValue.length >= 2) {
      integerPart = numericValue.slice(0, numericValue.length - 2);
      decimalPart = numericValue.slice(-2);
    } else if (numericValue.length === 1) {
      integerPart = '0';
      decimalPart = '0' + numericValue;
    } else {
      integerPart = '0';
      decimalPart = '00';
    }

    const newValue = `${integerPart}.${decimalPart}`;

    return newValue;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      handleFocus();

      return;
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      handleFocus();

      return;
    }
    if (e.key === 'Backspace') {
      e.preventDefault();

      const numericValue = String(values.unitPrice).replace('.', '').replace(/^0+/, '');
      const input = numericValue.slice(0, -1); // Remove last character

      const newValue = updateValue(input);

      setFieldValue('unitPrice', newValue);
    }
  };

  return (
    <FieldInput
      addonBefore="$"
      step={'0.01'}
      onFocus={handleFocus}
      onClick={handleFocus}
      ref={inputRef as any}
      onChange={(e): void => {
        handleChange(e?.target.value.toString() ?? '');
      }}
      onKeyDown={(e): void => handleKeyDown(e)}
      fieldName={fieldName}
      label={label}
      placeholder={placeholder}
      aria-controls="unitPrice"
    />
  );
};
