import { Select } from 'antd';
import { getBinLocations } from 'api/GetBinLocation';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  setForm: React.Dispatch<React.SetStateAction<any>>;
  formVals: {
    itemStatusId: string;
    conditionFinished: string;
    dispositionFinished: string;
    binLocationId: string;
  };
  record: RepairOrderToBePicked;
};

const BinSelectComponent: FC<Props> = ({ setForm, formVals, record }): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await getBinLocations({ dataAreaId: 'MDSI', inventLocationId: record.inventLocationId });
        const formattedData = resp.map((item) => item.wMSLocationId);

        setData(formattedData);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [record]);

  return (
    <Select
      loading={loading}
      showSearch
      allowClear
      options={data.map((item) => ({ label: item, value: item }))}
      value={formVals?.binLocationId}
      style={{ width: '100%' }}
      placeholder="Bin Location"
      onChange={(e) => setForm((prev: any) => ({ ...prev, binLocationId: e }))}
    />
  );
};

export const BinSelect = React.memo(BinSelectComponent);
