import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShipmentParams } from 'models/DockReceipt';

const initialState: ShipmentParams = {
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  returnDeletedDataOnly: undefined,
  trackingNumberContains: undefined,
  returnReference: undefined,
  warehouseIdContains: undefined,
  d365PurchaseOrderNumber: undefined,
  statuses: undefined,
  startDate: undefined,
  endDate: undefined,
  overrideSkipTake: undefined,
  offset: 0,
  returnInactiveDataOnly: undefined,
  take: undefined,
  vendorIdContains: undefined,
  receiptType: undefined,
  customerIdContains: undefined,
  accountIdContains: undefined,
  accountNumberContains: undefined,
  returnReferenceContains: undefined,
  mdsiOrderNumberContains: undefined
};

export const shipmentParams = createSlice({
  name: 'shipmentParams',
  initialState,
  reducers: {
    setIncludeInactiveData: (state, { payload }: PayloadAction<ShipmentParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setAccountIdContains: (state, { payload }: PayloadAction<ShipmentParams['accountIdContains']>) => {
      state.accountIdContains = payload;
    },
    setAccountNumberContains: (state, { payload }: PayloadAction<ShipmentParams['accountNumberContains']>) => {
      state.accountNumberContains = payload;
    },
    setReturnReferenceContains: (state, { payload }: PayloadAction<ShipmentParams['returnReferenceContains']>) => {
      state.returnReferenceContains = payload;
    },
    setMdsiOrderNumberContains: (state, { payload }: PayloadAction<ShipmentParams['mdsiOrderNumberContains']>) => {
      state.mdsiOrderNumberContains = payload;
    },
    setCustomerIdContains: (state, { payload }: PayloadAction<ShipmentParams['customerIdContains']>) => {
      state.customerIdContains = payload;
    },
    setIncludeDeletedData: (state, { payload }: PayloadAction<ShipmentParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<ShipmentParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ShipmentParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setTrackingNumberContains: (state, { payload }: PayloadAction<ShipmentParams['trackingNumberContains']>) => {
      state.trackingNumberContains = payload;
    },
    setStatuses: (state, { payload }: PayloadAction<ShipmentParams['statuses']>) => {
      state.statuses = payload;
    },
    setProcessingWarehouseContains: (state, { payload }: PayloadAction<ShipmentParams['warehouseIdContains']>) => {
      state.warehouseIdContains = payload;
    },
    setD365PurchaseOrderNumber: (state, { payload }: PayloadAction<ShipmentParams['d365PurchaseOrderNumber']>) => {
      state.d365PurchaseOrderNumber = payload;
    },
    setVendorIdContains: (state, { payload }: PayloadAction<ShipmentParams['vendorIdContains']>) => {
      state.vendorIdContains = payload;
    },
    setReceiptType: (state, { payload }: PayloadAction<ShipmentParams['receiptType']>) => {
      state.receiptType = payload;
    },
    setRangeValues: (state, action) => {
      state.startDate = action.payload ? action.payload[0] : undefined;
      state.endDate = action.payload ? action.payload[1] : undefined;
      state.offset = 0;
    },
    resetRangeValues: (state) => {
      state.startDate = undefined;
      state.endDate = undefined;
      state.offset = 0;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ShipmentParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<ShipmentParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setOverrideSkipTake: (state, { payload }: PayloadAction<ShipmentParams['overrideSkipTake']>) => {
      state.overrideSkipTake = payload;
    },
    setOffset: (state, { payload }: PayloadAction<ShipmentParams['offset']>) => {
      state.offset = payload;
    },
    setTake: (state, { payload }: PayloadAction<ShipmentParams['take']>) => {
      state.take = payload;
    }
  }
});

export const {
  setIncludeDeletedData,
  setIncludeInactiveData,
  setOrderByDirection,
  setOffset,
  setOrderByField,
  setOverrideSkipTake,
  setReturnDeletedDataOnly,
  setReturnInactiveDataOnly,
  setTake,
  resetRangeValues,
  setRangeValues,
  setTrackingNumberContains,
  setStatuses,
  setD365PurchaseOrderNumber,
  setProcessingWarehouseContains,
  setVendorIdContains,
  setReceiptType,
  setCustomerIdContains,
  setAccountNumberContains,
  setAccountIdContains,
  setMdsiOrderNumberContains,
  setReturnReferenceContains
} = shipmentParams.actions;
