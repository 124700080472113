import { ArrowLeftOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space, Spin, Typography } from 'antd';
import DropdownButton from 'antd/es/dropdown/dropdown-button';
import { createRepairOrder } from 'api/CreateRepairOrder';
import { getLookupTables } from 'api/GetLookupTables';
import { updateRepairOrder } from 'api/UpdateRepairOrder';
import { colors, toRgba } from 'common/styles/colors';
import { LineItemModal } from 'components/atoms/LineItemModal';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { LineItemTable } from 'components/molecules/LineItemTable';
import { RepairForm } from 'components/organisms/RepairForm';
import { RepairOrder, RepairOrderPayload } from 'components/organisms/RepairsTable';
import { FormikProvider, useFormik } from 'formik';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLookupTables, setPreviousDataAreaId, setRefetchLines, setSelectedRepair } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import xml2js from 'xml2js';

export const CreateRepair = (): JSX.Element => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { selectedRepair, refetchLines, selectedDataAreaId, previousDataAreaId } = useAppSelector((state) => state.app);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dataAreaId = Cookies.get('selectedDataAreaId');

  const saveButton = useRef<string>('');

  const repairFromCookie = Cookies.get(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`)
    ? JSON.parse(Cookies.get(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`) as string)
    : undefined;

  const dispatch = useDispatch();
  const nav = useNavigate();
  const formik = useFormik<RepairOrderPayload>({
    enableReinitialize: true,
    initialValues: {
      RepairAdmin: selectedRepair?.repairAdmin ?? '',
      CustomerReference: selectedRepair?.customerReference ?? '',
      RMANumber: '',
      RecId: selectedRepair?.recId ?? 0,
      CustomerAccountNumber: selectedRepair?.customerId ?? '',
      OrderDate: selectedRepair?.orderDate ?? moment().format('MM/DD/YYYY'),
      RepairLabId: selectedRepair?.repairLab ?? '',
      RepairOrderId: selectedRepair?.repairOrderId ?? '',
      RepairTypeId: selectedRepair?.repairType ?? '',
      RepairStatusId: selectedRepair?.status ?? 'OPEN',
      Lines: []
    },
    onSubmit: async (values) => {
      if (!values.CustomerAccountNumber || !values.RepairLabId || !values.OrderDate || !values.RepairAdmin || !values.RepairTypeId || !values.CustomerReference) {
        message.error('Please fill out all of the fields');

        return;
      }
      try {
        if (id) {
          const resp = await updateRepairOrder({ payload: { ...values, Lines: values.Lines }, dataAreaId: selectedDataAreaId });
          const obj: RepairOrder = {
            repairOrderId: resp?.repairOrderId ?? '',
            customerId: resp?.customerAccountNumber ?? '',
            orderDate: resp?.orderDate ?? undefined,
            status: resp?.repairStatusId ?? '',
            repairType: resp?.repairTypeId ?? '',
            repairLab: resp?.repairLabId ?? '',
            repairAdmin: resp?.repairAdmin ?? '',
            customerReference: resp?.customerReference ?? '',
            recId: resp?.recId ?? 0
          };

          if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
            Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(obj), { expires: 365 });
          } else Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(obj), { expires: 365, domain: 'mdsiinc.com' });

          message.success('Order successfully updated');
          if (saveButton.current === 'exit') nav('/');

          return;
        }
        const resp = await createRepairOrder({ payload: values, dataAreaId: selectedDataAreaId });

        message.success('Order successfully created');
        formik.resetForm();
        if (!id && saveButton.current === 'save') {
          const obj: RepairOrder = {
            repairOrderId: resp?.repairOrderId ?? '',
            customerId: resp?.customerAccountNumber ?? '',
            orderDate: resp?.orderDate ?? undefined,
            status: resp?.repairStatusId ?? '',
            repairType: resp?.repairTypeId ?? '',
            repairLab: resp?.repairLabId ?? '',
            repairAdmin: resp?.repairAdmin ?? '',
            customerReference: resp?.customerReference ?? '',
            recId: resp?.recId ?? 0
          };

          dispatch(setSelectedRepair(obj));
          if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
            Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(obj), { expires: 365 });
          } else Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(obj), { expires: 365, domain: 'mdsiinc.com' });

          nav(`/edit/${resp.repairOrderId}`);
        }
        if (saveButton.current === 'exit') nav('/');
      } catch (err) {
        console.log(err);
        message.error('There was an error processing your request');
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api';

      setLoading(true);
      const resp = await fetch(url, {
        method: 'POST',
        cache: 'default',
        headers: {
          'Content-Type': 'text/xml',
          SOAPAction: 'http://tempuri.org/IRepairModule/GetRepairOrderLines'
        },
        body: `
                <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tns:GetRepairOrderLines>
        <tns:dataAreaId>${selectedDataAreaId}</tns:dataAreaId>
        <tns:repairOrderId>${id}</tns:repairOrderId>
      </tns:GetRepairOrderLines>
    </soapenv:Body>
  </soapenv:Envelope>
              `
      });

      const textResponse = await resp.text();
      const xmlParser = new xml2js.Parser();

      xmlParser.parseStringPromise(textResponse).then((result) => {
        const rawData = result[`s:Envelope`]?.[`s:Body`][0]?.GetRepairOrderLinesResponse[0].GetRepairOrderLinesResult[0]?.['a:RepairLine'];
        const formattedData = rawData?.map((item: any) => {
          const recIdString = item?.['a:RecId'][0] || '';

          return {
            itemId: item?.['a:ItemId'][0],
            serialNumber: item?.['a:RepairSerialNumber'][0],
            unitPrice: item?.['a:RepairPrice'][0],
            sla: +item?.['a:SLABusinessDays'][0],
            status: item?.['a:RepairStageId'][0],
            dateStaged: item?.['a:DateStaged'][0],
            itemStatus: item?.['a:ItemStatusId'][0],
            technician: item?.['a:RepairTechnicianId'][0],
            salesOrder: item?.['a:SalesOrderId'][0],
            recId: +recIdString,
            repairOrderId: item?.['a:RepairOrderId'][0]
          };
        });

        formik.setFieldValue('Lines', formattedData);
      });

      setLoading(false);

      return textResponse;
    };

    const refreshDataTables = async () => {
      setIsRefreshing(true);
      try {
        const resp = await getLookupTables(selectedDataAreaId);

        dispatch(setLookupTables(resp.textResponse));
        dispatch(setPreviousDataAreaId(resp.dataAreaId));
        setIsRefreshing(false);
      } catch (err) {
        console.error(err);
        setIsRefreshing(false);
      }
    };

    if (dataAreaId?.toLowerCase() !== selectedDataAreaId?.toLowerCase()) {
      refreshDataTables();
    } else if (selectedDataAreaId.toLowerCase() !== previousDataAreaId?.toLowerCase()) {
      console.log(selectedDataAreaId.toLowerCase(), previousDataAreaId?.toLowerCase());
      refreshDataTables();
    }

    if (id) {
      if (repairFromCookie) {
        dispatch(setSelectedRepair(repairFromCookie));
      }
      fetchData();
      if (refetchLines) {
        fetchData();
        dispatch(setRefetchLines(false));
      }
    }
    if (!id) dispatch(setSelectedRepair(undefined));

    return () => {
      if (id && repairFromCookie) {
        if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
          Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(repairFromCookie), { expires: 365 });
        } else Cookies.set(`selectedRepair-${process.env.REACT_APP_COOKIE_PREFIX}`, JSON.stringify(repairFromCookie), { expires: 365, domain: 'mdsiinc.com' });
        dispatch(setSelectedRepair(undefined));
      }
    };
  }, [id, refetchLines, selectedDataAreaId, previousDataAreaId]);

  const saveAndExit = async (): Promise<void> => {
    saveButton.current = 'exit';
    await formik.submitForm();
  };

  return (
    <FormikProvider value={formik}>
      <Spin
        spinning={formik.isSubmitting || isRefreshing}
        indicator={<LoaderWithMessage marginTop={70} loadingMessage={isRefreshing ? 'Refreshing Data Tables...' : id ? 'Updating...' : 'Creating...'} />}>
        <Row justify={'space-between'} align={'middle'} style={{ marginBottom: 12, width: '100%' }}>
          <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
            {!id ? `Create Repair` : `Edit Repair ${selectedRepair?.repairOrderId}`}
          </Typography.Title>
          <Space>
            <Button onClick={() => nav('/')} icon={<ArrowLeftOutlined />}>
              Back
            </Button>
            <DropdownButton
              onClick={saveAndExit}
              style={{ width: 'fit-content' }}
              icon={<SaveOutlined />}
              menu={{
                items: [
                  {
                    key: '1',
                    label: 'Save',
                    onClick: () => {
                      saveButton.current = 'save';
                      formik.submitForm();
                    }
                  }
                ]
              }}
              type="primary">
              <CheckOutlined />
              Save & Exit
            </DropdownButton>
          </Space>
        </Row>
        <Row gutter={[20, 0]}>
          <Col span={6}>
            <RepairForm />
          </Col>
          <Col span={18}>
            <ColorCard color={toRgba(colors.royalBlueLight, 0.4)} title={'Line Items'} extra={<LineItemModal repairTypeId={formik.values.RepairTypeId} />}>
              <LineItemTable repairTypeId={selectedRepair?.repairType} loading={{ indicator: <LoaderWithMessage loadingMessage="Loading..." />, spinning: loading }} />
            </ColorCard>
          </Col>
        </Row>
      </Spin>
    </FormikProvider>
  );
};
