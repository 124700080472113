import { RepairOrderPayload } from 'components/organisms/RepairsTable';
import { toCamelCase } from './GetLookupTables';

type Props = {
  payload: RepairOrderPayload;
  dataAreaId?: string;
};

const formatDate = (date: string): Date => {
  const arg = new Date(date);

  return arg;
};

export const updateRepairOrder = async ({ payload, dataAreaId }: Props): Promise<any> => {
  const url = 'https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api';

  const body = `
                <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <UpdateRepairOrder xmlns="http://tempuri.org/">
      <order xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:CurrentRepairLine i:nil="true" />
        <d4p1:CustomerAccountNumber>${payload.CustomerAccountNumber}</d4p1:CustomerAccountNumber>
        <d4p1:CustomerReference>${payload.CustomerReference}</d4p1:CustomerReference>
         ${
           payload.Lines && payload.Lines.length
             ? `<d4p1:Lines> ${payload.Lines.map((line) => {
                 return `<d4p1:RepairLine>
                 <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:DateStaged>${formatDate(line.dateStaged || '').toISOString()}</d4p1:DateStaged>
        <d4p1:HoursWorked>2</d4p1:HoursWorked>
        <d4p1:InboundJournalId i:nil="true" />
        <d4p1:ItemId>${line.itemId || ''}</d4p1:ItemId>
        <d4p1:ItemStatusId>${line.itemStatus || ''}</d4p1:ItemStatusId>
        <d4p1:Picker />
        <d4p1:RecId>${line.recId || 0}</d4p1:RecId>
        <d4p1:RepairOrderId>${line.repairOrderId || ''}</d4p1:RepairOrderId>
        <d4p1:RepairPrice>${Number(line.unitPrice) || 1.0}</d4p1:RepairPrice>
        <d4p1:RepairSerialNumber>${line.serialNumber || ''}</d4p1:RepairSerialNumber>
        <d4p1:RepairStageId>${line.status || ''}</d4p1:RepairStageId>
        <d4p1:RepairTechnicianId>${line.technician || ''}</d4p1:RepairTechnicianId>
        <d4p1:SLABusinessDays>${Number(line.sla) || 10}</d4p1:SLABusinessDays>
        <d4p1:SalesOrderId>${line.salesOrder || ''}</d4p1:SalesOrderId>
                        </d4p1:RepairLine>`;
               }).join('')}
              </d4p1:Lines>`
             : `<d4p1:Lines />`
         }
        ${payload?.OrderDate ? `<d4p1:OrderDate>${formatDate(payload.OrderDate || '').toISOString()}</d4p1:OrderDate>` : ''}
        <d4p1:RMANumber>test</d4p1:RMANumber>
        <d4p1:RecId>${payload.RecId}</d4p1:RecId>
        <d4p1:RepairAdmin>${payload.RepairAdmin}</d4p1:RepairAdmin>
        <d4p1:RepairLabId>${payload.RepairLabId}</d4p1:RepairLabId>
        <d4p1:RepairOrderId>${payload.RepairOrderId}</d4p1:RepairOrderId>
        <d4p1:RepairStatusId>${payload.RepairStatusId}</d4p1:RepairStatusId>
        <d4p1:RepairTypeId>${payload.RepairTypeId}</d4p1:RepairTypeId>
      </order>
      <dataAreaId>${dataAreaId || 'MDSI'}</dataAreaId>
    </UpdateRepairOrder>
  </s:Body>
</s:Envelope>
              `;

  const resp = await fetch(url, {
    method: 'POST',
    cache: 'default',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/UpdateRepairOrder'
    },
    body
  });

  const responseText = await resp.text();

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(responseText, 'text/xml');
  const test = xmlDoc.getElementsByTagName('UpdateRepairOrderResult')[0];

  const formattedData = Array.from(test?.childNodes || [])
    .map((node) => {
      return { [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent };
    })
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});

  return formattedData;
};
