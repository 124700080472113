import { parseSoapResponseTest } from 'components/organisms/LineItemSearch';

export const getDataAreaIds = async (): Promise<string[]> => {
  try {
    const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/xml',
        SOAPAction: 'http://tempuri.org/IRepairModule/GetAllDataAreaIds'
      },
      body: `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
                        <soapenv:Header/>
                        <soapenv:Body>
                    <tns:GetAllDataAreaIds />
            </soapenv:Body>
          </soapenv:Envelope>
                      `
    });

    const textResponse = await resp.text();

    const dataAreaIds = parseSoapResponseTest(textResponse, true);

    const filteredData = new Set(dataAreaIds);
    const uniqueDataAreaIds = Array.from(filteredData);

    return uniqueDataAreaIds ?? [];
  } catch (error) {
    console.log(error);

    return [];
  }
};
