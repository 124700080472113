import { FormOutlined } from '@ant-design/icons';
import { useAuth0, User } from '@auth0/auth0-react';
import { Button, message, Modal, Space, Spin, Tooltip, Typography } from 'antd';
import { saveFaultHistory } from 'api/SaveFaultHistory';
import { saveResolutionHistory } from 'api/SaveResolutionHistory';
import { setRepairLineNote } from 'api/SetRepairLineNote';
import { updateRepairLine } from 'api/UpdateRepairLine';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { POReceiptLine } from 'components/molecules/LineItemTable';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { LineItemTechForm } from 'components/organisms/LineItemTechForm';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRefetchLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { CompleteItemModal } from './CompleteItemModal';

type Props = {
  repairTypeId?: string;
  isEdit?: boolean;
  isLineItem?: boolean;
  record?: Partial<RepairOrderToBePicked>;
  idx?: number;
};

export type RepairLineTechUpdate = {
  status: string;
  faultCodeIds: string[];
  resolutionCodeIds: string[];
  resolutionNotes: string;
  faultNotes: string;
  newRepairLineNote: string;
};

export const LineItemTechQueueComponent: FC<Props> = ({ repairTypeId, isLineItem = false, record, idx }) => {
  const [visible, setVisible] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const formik = useFormik<RepairLineTechUpdate>({
    enableReinitialize: true,
    initialValues: {
      status: record?.repairStageId || '',
      faultCodeIds: [],
      resolutionCodeIds: [],
      faultNotes: '',
      resolutionNotes: '',
      newRepairLineNote: ''
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.faultCodeIds.length) {
          await saveFaultHistory({ dataAreaId: selectedDataAreaId, record: record as RepairOrderToBePicked, faultCodes: values.faultCodeIds, notes: values.faultNotes });
        }
        if (values.resolutionCodeIds.length) {
          await saveResolutionHistory({
            dataAreaId: selectedDataAreaId,
            record: record as RepairOrderToBePicked,
            resolutionCodes: values.resolutionCodeIds,
            notes: values.resolutionNotes
          });
        }

        if (record?.repairStageId?.toLowerCase().trim() !== values.status?.toLowerCase().trim() && !isLineItem) {
          const reformattedLine: POReceiptLine = {
            itemId: record?.itemId ?? '',
            itemStatus: record?.itemStatusId ?? '',
            recId: +(record?.recId ?? 0),
            repairOrderId: record?.repairOrderId ?? '',
            serialNumber: record?.repairSerialNumber ?? '',
            status: values.status,
            technician: record?.repairTechnicianId ?? '',
            unitPrice: record?.repairPrice ?? 0,
            sla: +(record?.sLABusinessDays ?? 0),
            dateStaged: record?.dateStaged ?? '',
            salesOrder: record?.salesOrderId ?? ''
          };

          await updateRepairLine({ line: reformattedLine, dataAreaId: selectedDataAreaId });
          dispatch(setRefetchLines(true));
        }

        if (values.newRepairLineNote) {
          await setRepairLineNote({ repairLineRecId: +(record?.recId ?? 0) as number, dataAreaId: selectedDataAreaId, user: user as User, note: values.newRepairLineNote });
        }

        message.success('History Saved');
        setLoading(false);
        formik.resetForm();
        setVisible(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  });

  const handleCancel = (): void => {
    formik.resetForm();
    setVisible(false);
  };
  const handleSubmit = (): void => {
    formik.submitForm();
  };

  return (
    <FormikProvider value={formik}>
      <Tooltip title="Manage Repair Line">
        <Button style={{ borderColor: '#3395ff' }} size="middle" icon={<FormOutlined style={{ color: '#3395ff' }} />} onClick={() => setVisible(true)} />
      </Tooltip>
      <Modal
        footer={(originNode) => {
          if (isLineItem) {
            return originNode;
          }

          return (
            <Space>
              {originNode}
              <CompleteItemModal setFirstModalVisible={setVisible} repairLineNote={formik.values.newRepairLineNote} record={record as RepairOrderToBePicked} />
            </Space>
          );
        }}
        okText={'Save'}
        okButtonProps={{ disabled: !formik.dirty }}
        destroyOnClose
        width={1000}
        closable={false}
        open={visible}
        onCancel={handleCancel}
        onOk={() => handleSubmit()}>
        <ColorCard color={toRgba(colors.orangeWeb, 0.4)} title={`Manage Repair Line ${record?.recId}`}>
          <Spin spinning={loading} indicator={<LoaderWithMessage loadingMessage="Updating..." />}>
            <Space size={12} style={{ width: '100%', marginBottom: 16 }}>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Repair Order Id: <Typography.Text style={{ fontSize: 13 }}>{record?.repairOrderId}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Item Id: <Typography.Text style={{ fontSize: 13 }}>{record?.itemId}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Serial Number: <Typography.Text style={{ fontSize: 13 }}>{record?.repairSerialNumber}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Technician: <Typography.Text style={{ fontSize: 13 }}>{record?.repairTechnicianId || 'N/A'}</Typography.Text>
              </Typography.Text>
            </Space>
            <LineItemTechForm record={record as Partial<RepairOrderToBePicked>} isLineItem={isLineItem} />
          </Spin>
        </ColorCard>
      </Modal>
    </FormikProvider>
  );
};

export const LineItemTechQueueModal = React.memo(LineItemTechQueueComponent);
