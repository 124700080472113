import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DockReceiptParams } from 'models/DockReceipt';

const initialState: DockReceiptParams = {
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  returnDeletedDataOnly: undefined,
  trackingNumberContains: undefined,
  loadIdContains: undefined,
  warehouseIdContains: undefined,
  carrierIdContains: undefined,
  statuses: 'Open',
  startDate: undefined,
  endDate: undefined,
  overrideSkipTake: undefined,
  offset: 0,
  returnInactiveDataOnly: undefined,
  take: undefined
};

export const lindaParams = createSlice({
  name: 'lindaParams',
  initialState,
  reducers: {
    setIncludeInactiveData: (state, { payload }: PayloadAction<DockReceiptParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setIncludeDeletedData: (state, { payload }: PayloadAction<DockReceiptParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<DockReceiptParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<DockReceiptParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setTrackingNumberContains: (state, { payload }: PayloadAction<DockReceiptParams['trackingNumberContains']>) => {
      state.trackingNumberContains = payload;
    },
    setLoadIdContains: (state, { payload }: PayloadAction<DockReceiptParams['loadIdContains']>) => {
      state.loadIdContains = payload;
    },
    setWarehouseIdContains: (state, { payload }: PayloadAction<DockReceiptParams['warehouseIdContains']>) => {
      state.warehouseIdContains = payload;
    },
    setCarrierIdContains: (state, { payload }: PayloadAction<DockReceiptParams['carrierIdContains']>) => {
      state.carrierIdContains = payload;
    },
    setStatuses: (state, { payload }: PayloadAction<DockReceiptParams['statuses']>) => {
      state.statuses = payload;
    },
    setRangeValues: (state, action) => {
      state.startDate = action.payload ? action.payload[0] : undefined;
      state.endDate = action.payload ? action.payload[1] : undefined;
      state.offset = 0;
    },
    resetRangeValues: (state) => {
      state.startDate = undefined;
      state.endDate = undefined;
      state.offset = 0;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<DockReceiptParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<DockReceiptParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setOverrideSkipTake: (state, { payload }: PayloadAction<DockReceiptParams['overrideSkipTake']>) => {
      state.overrideSkipTake = payload;
    },
    setOffset: (state, { payload }: PayloadAction<DockReceiptParams['offset']>) => {
      state.offset = payload;
    },
    setTake: (state, { payload }: PayloadAction<DockReceiptParams['take']>) => {
      state.take = payload;
    }
  }
});

export const {
  setIncludeDeletedData,
  setIncludeInactiveData,
  setOrderByDirection,
  setCarrierIdContains,
  setLoadIdContains,
  setOffset,
  setOrderByField,
  setOverrideSkipTake,
  setReturnDeletedDataOnly,
  setReturnInactiveDataOnly,
  setStatuses,
  setTake,
  setRangeValues,
  resetRangeValues,
  setTrackingNumberContains,
  setWarehouseIdContains
} = lindaParams.actions;
