import { BackTop, Button, Col, Layout, Row, Spin, Table, Typography } from 'antd';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { AtatWalker } from 'components/atoms/atatWalker/AtatWalker';
import { useAboutPageDataTest } from 'hooks/useAboutPageDataTest';
import '../common/styles/checkmarkStyle.less';

const styles: InlineStylesModel = {
  headerTitle: {
    color: 'rgb(67, 67, 73)',
    font: isMobileOnly ? '500 0.75rem Poppins' : '500 1rem Poppins',
    fontSize: 16
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobileOnly ? 'center' : 'none'
  },
  statusIcon: {
    fontSize: '1.5rem',
    marginRight: isMobileOnly ? '0px' : '15px'
  },
  statusText: {
    display: isMobileOnly ? 'none' : 'block'
  },
  tableBody: {
    backgroundColor: '#fff',
    fontSize: isMobileOnly ? '0.7em' : '1em',
    borderTop: '2px solid black'
  }
};

enum ApiStatus {
  online = 'Online',
  offline = 'Offline'
}

export const About: React.FunctionComponent = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();

  const pageData = useAboutPageDataTest([
    { baseUrl: process.env.REACT_APP_COOKIE_MONSTER_BASE_URL ?? '', key: process.env.REACT_APP_API_HOST_KEY_COOKIE_MONSTER ?? '' },
    { baseUrl: process.env.REACT_APP_BASIL_BASE_URL ?? '', key: process.env.REACT_APP_API_HOST_KEY_BASIL ?? '' }
  ]);

  const tableData = pageData?.map(({ version, apiName, isLoading }, idx) => ({
    version: isLoading ? <Spin spinning /> : version || 'failed',
    key: idx,
    status: !isLoading ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {version ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{version ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: !isLoading ? (
      <Row>
        <Col>{apiName || 'Unable to retrieve app name'}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  }));

  const columnHeaders = [
    {
      dataIndex: 'apiName',
      key: 'apiName',
      title: 'API Name'
    },
    {
      dataIndex: 'version',
      key: 'version',
      title: 'Version'
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status'
    }
  ];
  const handleHomePage = (): void => {
    navigate(`/`);
  };
  /* ******************** Table Data ******************** */

  const mainAppVersion = (): string => {
    const appName = process.env.REACT_APP_APPLICATION_NAME as string;
    const versionNumber = process.env.REACT_APP_VERSION_NUMBER as string;
    const splitVersion = versionNumber?.split('-');

    return `${appName} ${splitVersion[splitVersion.length - 1]}`;
  };

  return (
    <Layout.Content>
      <BackTop />
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <AntPageTitle text="About Website" />
              <Typography.Link href="/" style={styles.headerTitle}>
                {mainAppVersion()}
              </Typography.Link>
            </Col>
            <Col>
              <Button onClick={handleHomePage} type="primary">
                Back to Home
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={13} sm={24}>
          <Table rowKey={(record): number => record.key} columns={columnHeaders} bordered={true} pagination={false} dataSource={tableData} style={styles.tableBody} />
        </Col>
        <Col md={10} sm={24}>
          <AtatWalker />
        </Col>
      </Row>
    </Layout.Content>
  );
};
