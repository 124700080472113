import { parseSoapResponse } from 'api/GetLookupTables';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

const RepairStatusSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const [loading, setLoading] = useState(false);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const test123 = parseSoapResponse(lookupTables, 'RepairStatuses');

    if (lookupTables) {
      const formattedData = test123?.map((item) => item?.['b:RepairStatusId']);

      setData(formattedData);
    }
  }, [lookupTables]);

  return <FieldInputSelect loading={loading} options={data?.map((item) => ({ label: item, value: item }))} fieldName="RepairStatusId" label="Repair Order Status" placeholder="Repair Order Status" />;
};

export const RepairStatusSelect = React.memo(RepairStatusSelectComponent);
