import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';

export function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const saveResolutionHistory = async ({
  record,
  dataAreaId,
  resolutionCodes,
  notes
}: {
  record: Partial<RepairOrderToBePicked>;
  dataAreaId: string;
  resolutionCodes: string[];
  notes: string;
}): Promise<any> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/SaveResolutionCodeHistory'
    },
    body: `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
              <s:Body>
    <SaveResolutionCodeHistory xmlns="http://tempuri.org/">
      <dataAreaId xmlns:d4p1="http://www.w3.org/2001/XMLSchema-instance">${dataAreaId}</dataAreaId>
      <repairLineRecId>${Number(record?.recId)}</repairLineRecId>
      <repairorderid xmlns:d4p1="http://www.w3.org/2001/XMLSchema-instance">${record?.repairOrderId}</repairorderid>
      <notes xmlns:d4p1="http://www.w3.org/2001/XMLSchema-instance">${notes}</notes>
      <resolutionCodes>
        <int xmlns="http://schemas.microsoft.com/2003/10/Serialization/Arrays">1</int>
      </resolutionCodes>
    </SaveResolutionCodeHistory>
  </s:Body>
            </s:Envelope>
                    `
  });

  const textResponse = await resp.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(textResponse, 'text/xml');

  const parsedCollection = xmlDoc.getElementsByTagName('a:RepairFaultCode');
  const formattedData = Array.from(parsedCollection).map((item) => {
    return Array.from(item.childNodes)
      .map((node) => ({
        [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]:
          node.nodeName === 'a:Dimension'
            ? Array.from(node.childNodes)
                .map((node) => ({ [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {})
            : node.textContent
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  });

  return textResponse;
};
