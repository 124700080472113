export function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const getFaultCodes = async (dataAreaId = 'MDSI'): Promise<any[]> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetFaultCodes'
    },
    body: `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
        <soapenv:Header/>
        <soapenv:Body>
          <tns:GetFaultCodes>
            <tns:dataAreaId>${dataAreaId}</tns:dataAreaId>
          </tns:GetFaultCodes>
        </soapenv:Body>
      </soapenv:Envelope>
                  `
  });

  const textResponse = await resp.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(textResponse, 'text/xml');
  const parsedCollection = xmlDoc.getElementsByTagName('a:RepairFaultCode');
  const formattedData = Array.from(parsedCollection).map((item) => {
    return Array.from(item.childNodes)
      .map((node) => ({
        [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]:
          node.nodeName === 'a:Dimension'
            ? Array.from(node.childNodes)
                .map((node) => ({ [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {})
            : node.textContent
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  });

  return formattedData;
};
