import { getFaultCodes } from 'api/GetFaultCodes';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  isStage?: boolean;
  setForm?: React.Dispatch<
    React.SetStateAction<{
      repairStageId: string;
      technicianId: string;
    }>
  >;
};

const FaultCodeSelectComponent: FC<Props> = ({ isStage = false, setForm }): JSX.Element => {
  const [data, setData] = useState<{ faultCode: string; faultCodeDescription: string }[]>([]);
  const { lookupTables, selectedDataAreaId } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = await getFaultCodes(selectedDataAreaId);

      const formattedData = test123?.map((item) => {
        return { faultCode: item?.faultCode, faultCodeDescription: item?.faultCodeDescription };
      });

      setData(formattedData);

      // const formattedData = test123?.map((item) => item?.['b:RepairStageId']);

      // setData(formattedData);
    };

    fetchData();
  }, [lookupTables]);

  return (
    <FieldInputSelect
      fieldName="faultCodeIds"
      label="Fault Code"
      mode="multiple"
      options={data?.map((item) => ({ label: `${item.faultCode} - ${item.faultCodeDescription}`, value: item.faultCode }))}
      placeholder="Fault Code"
    />
  );
};

export const FaultCodeSelect = React.memo(FaultCodeSelectComponent);
