import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import moment from 'moment';
import { FC } from 'react';
import * as xlsx from 'xlsx';

type Props = {
  data: RepairOrderToBePicked[];
};

export const ExportTableButton: FC<Props> = ({ data }) => {
  const handleExport = async (): Promise<void> => {
    const formattedData = data.map((item) => {
      return {
        RepairOrderId: item.repairOrderId,
        CustomerId: item.customerAccountNum,
        RepairStage: item.repairStageId,
        ItemId: item.itemId,
        SerialNumber: item.repairSerialNumber,
        DateStaged: moment(item.dateStaged ?? '').format('MM/DD/YYYY'),
        Warehouse: item.inventLocationId,
        BinLocation: item.wMSLocationId,
        RepairLab: item.repairLabId,
        Technician: item.repairTechnicianId
      };
    });
    const worksheeet = xlsx.utils.json_to_sheet(formattedData);
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheeet, 'AX09-Repairs-Queue');
    xlsx.writeFile(workbook, `AX09-Repairs-Queue.xlsx`);
  };

  return (
    <Button onClick={handleExport} style={{ borderColor: 'rgb(31, 30, 46)' }}>
      Export
      <ExportOutlined />
    </Button>
  );
};
