import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { POReceiptParamsModel } from 'models/POReceiptModel';

const initialState: POReceiptParamsModel = {
  purchaseOrderNumberContains: undefined,
  customerOrderNumberContains: undefined,
  vendorNameContains: undefined,
  warehouseContains: undefined,
  startDate: undefined,
  endDate: undefined,
  dateFilter: undefined,
  warehouseBeginsWith: undefined,
  offset: 0
};

export const grannyBirdParams = createSlice({
  name: 'grannyBirdParams',
  initialState,
  reducers: {
    setPurchaseOrderNumberContains: (state, { payload }: PayloadAction<POReceiptParamsModel['purchaseOrderNumberContains']>): void => {
      state.purchaseOrderNumberContains = payload;
    },
    setCustomerOrderNumberContains: (state, { payload }: PayloadAction<POReceiptParamsModel['customerOrderNumberContains']>): void => {
      state.customerOrderNumberContains = payload;
    },
    setVendorContains: (state, { payload }: PayloadAction<POReceiptParamsModel['vendorNameContains']>): void => {
      state.vendorNameContains = payload;
    },
    setWarehouseContains: (state, { payload }: PayloadAction<POReceiptParamsModel['warehouseContains']>): void => {
      state.warehouseContains = payload;
    },
    setWarehouseBeginsWith: (state, { payload }: PayloadAction<POReceiptParamsModel['warehouseBeginsWith']>): void => {
      state.warehouseBeginsWith = payload;
    },
    setStartDate: (state, { payload }: PayloadAction<POReceiptParamsModel['startDate']>): void => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }: PayloadAction<POReceiptParamsModel['endDate']>): void => {
      payload === undefined ? (state.dateFilter = undefined) : (state.dateFilter = 'DateRange');
      state.endDate = payload;
    },
    setDateFilter: (state, { payload }: PayloadAction<POReceiptParamsModel['dateFilter']>): void => {
      state.dateFilter = payload;
    },
    setOffset: (state): void => {
      state.offset += 25;
    }
  }
});

export const { setPurchaseOrderNumberContains, setCustomerOrderNumberContains, setVendorContains, setWarehouseContains, setStartDate, setEndDate, setDateFilter, setOffset, setWarehouseBeginsWith } =
  grannyBirdParams.actions;
