import { HistoryOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import { getRepairLineHistory } from 'api/GetRepairLineHistory';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';

type Props = {
  repairTypeId?: string;
  isEdit?: boolean;
  record?: Partial<RepairOrderToBePicked>;
  idx?: number;
};

export type RepairLineTechUpdate = {
  status: string;
  faultCodeId: string;
  resolutionCodeId: string;
  notes: string;
};

export const RepairLineHistoryModalComponent: FC<Props> = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const [data, setData] = useState<{ faultHistory: any[]; resolutionHistory: any[] }>({ faultHistory: [], resolutionHistory: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        setLoading(true);
        const data = await getRepairLineHistory({ record: record as RepairOrderToBePicked, dataAreaId: selectedDataAreaId });

        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (visible) fetch();
  }, [record, visible]);

  const handleCancel = (): void => {
    setVisible(false);
  };
  const handleSubmit = (): void => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="History">
        <Button size="middle" style={{ borderColor: '#ffa533' }} icon={<HistoryOutlined style={{ color: '#ffa533' }} />} onClick={() => setVisible(true)} />
      </Tooltip>
      <Modal okText={'Exit'} destroyOnClose width={950} closable={false} open={visible} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} onOk={() => handleSubmit()}>
        <ColorCard loading={loading} color={toRgba(colors.orangeWeb, 0.4)} title={`Repair Line History: ${record?.repairOrderId}`}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Table
                dataSource={data.faultHistory}
                pagination={false}
                size="small"
                rowKey={(item) => item.faultCodeId}
                columns={[
                  {
                    key: 'faultCode',
                    title: 'Fault Code',
                    dataIndex: 'faultCode',
                    width: 200
                  },
                  {
                    key: 'faultCodeDescription',
                    title: 'Description',
                    dataIndex: 'faultCodeDescription',
                    width: 200
                  },
                  {
                    title: 'Last Updated',
                    key: 'lastUpdated',
                    dataIndex: 'lastUpdated',
                    render: (text: string) => moment(text).format('MM/DD/YYYY')
                  },
                  {
                    key: 'notes',
                    title: 'Notes',
                    dataIndex: 'notes',
                    width: 300
                  }
                ]}
              />
            </Col>
            {/* <Col span={24}>
              <Divider style={{ margin: 10 }} type="horizontal" orientation="left">
                Resolution History
              </Divider>
            </Col> */}
            <Col span={24}>
              <Table
                dataSource={data.resolutionHistory}
                pagination={false}
                size="small"
                rowKey={(item) => item.resolutionCodeId}
                columns={[
                  {
                    key: 'resolutionCode',
                    title: 'Resolution Code',
                    dataIndex: 'resolutionCode',
                    width: 200
                  },
                  {
                    key: 'resolutionCodeDescription',
                    title: 'Description',
                    dataIndex: 'resolutionCodeDescription',
                    width: 200
                  },
                  {
                    title: 'Last Updated',
                    key: 'lastUpdated',
                    dataIndex: 'lastUpdated',
                    render: (text: string) => moment(text).format('MM/DD/YYYY')
                  },
                  {
                    key: 'notes',
                    title: 'Notes',
                    dataIndex: 'notes',
                    width: 300
                  }
                ]}
              />
            </Col>
          </Row>
        </ColorCard>
      </Modal>
    </>
  );
};

export const RepairLineHistoryModal = React.memo(RepairLineHistoryModalComponent);
