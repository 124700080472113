import { Col, Row, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { getAllLinesAwaitingSalesId } from 'api/GetAllLinesAwaitingSalesId';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { FancyInput } from 'components/ui/filters/FancyInput';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/store';

export type RepairOrderToAssignSoNumber = {
  isValidationEnforced: string;
  dateStaged: string;
  hoursWorked: string;
  inboundJournalId: string;
  itemId: string;
  itemStatusId: string;
  picker: string;
  recId: string;
  repairOrderId: string;
  repairPrice: string;
  repairSerialNumber: string;
  repairStageId: string;
  repairTechnicianId: string;
  sLABusinessDays: string;
  salesOrderId: string;
  customerAccountNum: string;
  customerReference: string;
  error: string;
  orderDate: string;
  repairAdmin: string;
  repairLabId: string;
};

type Props = TableProps & {
  repairTypeId?: string;
  setSelectedRows: React.Dispatch<React.SetStateAction<RepairOrderToAssignSoNumber[]>>;
  selectedRows: RepairOrderToAssignSoNumber[];
};

export const SOBillingTableComponent: FC<Props> = ({ setSelectedRows, selectedRows, ...rest }) => {
  const { selectedDataAreaId, refetchLines } = useAppSelector((state) => state.app);
  const [tableData, setTableData] = useState<RepairOrderToAssignSoNumber[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp: any[] = await getAllLinesAwaitingSalesId({ dataAreaId: selectedDataAreaId ?? 'mdsi' });
      const uniqueArray = resp.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) => t.repairOrderId === item.repairOrderId && t.repairSerialNumber === item.repairSerialNumber && t.itemId === item.itemId && t.customerAccountNum === item.customerAccountNum
          )
      );

      setTableData(uniqueArray);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const [filterVals, setFilterVals] = useState({
    repairOrderId: '',
    itemId: '',
    serialNumber: '',
    repairStageId: '',
    customerId: '',
    technicianId: '',
    repairLabId: '',
    repairType: '',
    status: '',
    itemStatusId: '',
    repairAdmin: ''
  });

  useEffect(() => {
    fetchData();
  }, [selectedDataAreaId]);
  useEffect(() => {
    if (refetchLines) {
      fetchData();
    }
  }, [refetchLines]);

  const columns: ColumnsType<Partial<RepairOrderToAssignSoNumber>> = [
    {
      title: 'Repair Order ID',
      dataIndex: 'repairOrderId',
      key: 'repairOrderId'
    },
    {
      title: 'Customer Id',
      dataIndex: 'customerAccountNum',
      key: 'customerAccountNum'
    },
    {
      title: 'Repair Stage',
      dataIndex: 'repairStageId',
      key: 'repairStageId'
    },
    {
      title: 'Item Id',
      dataIndex: 'itemId',
      key: 'itemId'
    },
    {
      title: 'Serial Number',
      dataIndex: 'repairSerialNumber',
      key: 'repairSerialNumber'
    },
    {
      title: 'Item Status',
      dataIndex: 'itemStatusId',
      key: 'itemStatusId'
    },
    {
      title: 'Repair Lab',
      dataIndex: 'repairLabId',
      key: 'repairLabId'
    },
    {
      title: 'Repair Admin',
      dataIndex: 'repairAdmin',
      key: 'repairAdmin'
    }
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   align: 'center',
    //   render: (text, record) => {
    //     return (
    //       <Space>
    //         <LineItemTechQueueModal record={record as RepairOrderToBePicked} />
    //         <RepairLineHistoryModal record={record} />
    //       </Space>
    //     );
    //   }
    // }
  ];
  const filteredData = tableData?.filter((item) => {
    return (
      item?.repairOrderId?.toLowerCase().includes(filterVals.repairOrderId?.toLowerCase()) &&
      item?.customerAccountNum?.toLowerCase().includes(filterVals.customerId?.toLowerCase()) &&
      item?.itemId?.toLowerCase().includes(filterVals.itemId?.toLowerCase()) &&
      item?.repairSerialNumber?.toLowerCase().includes(filterVals.serialNumber?.toLowerCase()) &&
      item?.repairStageId?.toLowerCase().includes(filterVals.repairStageId?.toLowerCase()) &&
      item?.repairAdmin?.toLowerCase().includes(filterVals.repairAdmin?.toLowerCase()) &&
      item?.repairLabId?.toLowerCase().includes(filterVals.repairLabId?.toLowerCase()) &&
      item?.itemStatusId?.toLowerCase().includes(filterVals.itemStatusId?.toLowerCase())
    );
  });
  const rowSelection: TableProps<RepairOrderToAssignSoNumber>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: RepairOrderToAssignSoNumber[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (row: RepairOrderToAssignSoNumber) => ({
      name: `${row.repairOrderId}-${row.repairSerialNumber}-${row.itemId}-${row.customerAccountNum}`
    }),
    selectedRowKeys: selectedRows.map((row) => `${row.repairOrderId}-${row.repairSerialNumber}-${row.itemId}-${row.customerAccountNum}`)
  };

  return (
    <>
      <Row style={{ background: 'white', padding: 16, margin: 0 }} gutter={[8, 8]}>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairOrderId: '' }));
              setFilterVals((prev) => ({ ...prev, repairOrderId: e.target.value }));
            }}
            placeholder="Repair Order Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, customerId: '' }));
              setFilterVals((prev) => ({ ...prev, customerId: e.target.value }));
            }}
            placeholder="Customer Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, itemId: '' }));
              setFilterVals((prev) => ({ ...prev, itemId: e.target.value }));
            }}
            placeholder="Item Id"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, serialNumber: '' }));
              setFilterVals((prev) => ({ ...prev, serialNumber: e.target.value }));
            }}
            placeholder="Serial Number"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, itemStatusId: '' }));
              setFilterVals((prev) => ({ ...prev, itemStatusId: e.target.value }));
            }}
            placeholder="Item Status"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairStageId: '' }));
              setFilterVals((prev) => ({ ...prev, repairStageId: e.target.value }));
            }}
            placeholder="Repair Stage"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairLabId: '' }));
              setFilterVals((prev) => ({ ...prev, repairLabId: e.target.value }));
            }}
            placeholder="Repair Lab"
          />
        </Col>
        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={3}>
          <FancyInput
            onChange={(e) => {
              if (!e) return setFilterVals((prev) => ({ ...prev, repairAdmin: '' }));
              setFilterVals((prev) => ({ ...prev, repairAdmin: e.target.value }));
            }}
            placeholder="Admin"
          />
        </Col>
      </Row>
      <Table
        dataSource={filteredData}
        virtual
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        loading={{ indicator: <LoaderWithMessage loadingMessage="Loading..." />, spinning: loading }}
        size="small"
        columns={columns}
        rowKey={(record: RepairOrderToAssignSoNumber): string => `${record.repairOrderId}-${record.repairSerialNumber}-${record.itemId}-${record.customerAccountNum}`}
        pagination={{ pageSize: 25 }}
        {...(rest as TableProps)}
      />
    </>
  );
};

export const SOBillingTable = React.memo(SOBillingTableComponent);
