export const getRepairLineNote = async ({ repairLineRecId, dataAreaId }: { repairLineRecId: number; dataAreaId: string }): Promise<string> => {
  const payload = `
       <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <GetRepairLineNote xmlns="http://tempuri.org/">
      <repairLineRecId>${repairLineRecId}</repairLineRecId>
      <dataAreaId>${dataAreaId}</dataAreaId>
    </GetRepairLineNote>
  </s:Body>
</s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetRepairLineNote'
    },
    body: payload
  });

  const textResponse = await resp.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(textResponse, 'text/xml');
  const parsedNoteString = xmlDoc.getElementsByTagName('GetRepairLineNoteResult')[0]?.textContent || '';

  return parsedNoteString;
};
