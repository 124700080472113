import { POReceiptLine } from 'components/molecules/LineItemTable';
import { formatDate } from './CreateRepairOrder';

export const updateRepairLine = async ({ line, dataAreaId }: { line: POReceiptLine; dataAreaId: string }): Promise<string> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/UpdateRepairLine'
    },
    body: `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <UpdateRepairLine xmlns="http://tempuri.org/">
          <line xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
            <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
            <d4p1:DateStaged>${formatDate(line.dateStaged || '').toISOString()}</d4p1:DateStaged>
            <d4p1:HoursWorked>2</d4p1:HoursWorked>
            <d4p1:InboundJournalId i:nil="true" />
            <d4p1:ItemId>${line.itemId || ''}</d4p1:ItemId>
            <d4p1:ItemStatusId>${line.itemStatus || ''}</d4p1:ItemStatusId>
            <d4p1:Picker />
            <d4p1:RecId>${line.recId || 0}</d4p1:RecId>
            <d4p1:RepairOrderId>${line.repairOrderId || ''}</d4p1:RepairOrderId>
            <d4p1:RepairPrice>${Number(line.unitPrice) || 1.0}</d4p1:RepairPrice>
            <d4p1:RepairSerialNumber>${line.serialNumber || ''}</d4p1:RepairSerialNumber>
            <d4p1:RepairStageId>${line.status || ''}</d4p1:RepairStageId>
            <d4p1:RepairTechnicianId>${line.technician || ''}</d4p1:RepairTechnicianId>
            <d4p1:SLABusinessDays>${Number(line.sla) || 10}</d4p1:SLABusinessDays>
            <d4p1:SalesOrderId>${line.salesOrder || ''}</d4p1:SalesOrderId>
        </line>
      <dataAreaId>${dataAreaId}</dataAreaId>
    </UpdateRepairLine>
  </s:Body>
</s:Envelope>`
  });

  const textResponse = await resp.text();

  return textResponse;
  //   const parsedResp = parseSoapResponse(textResponse, table ?? '');
};
