import { toCamelCase } from './GetLookupTables';

export const getRepairLineItemStatusesByCustomer = async ({ custAccount, repairTypeId, dataAreaId }: { custAccount: string; dataAreaId: string; repairTypeId: string }): Promise<any[]> => {
  const payload = `
         <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
            <s:Body>
            <GetRepairItemStatus xmlns="http://tempuri.org/">
                <custAccount>${custAccount}</custAccount>
                <repairTypeId>${repairTypeId}</repairTypeId>
                <dataAreaId>${dataAreaId}</dataAreaId>
            </GetRepairItemStatus>
            </s:Body>
        </s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/GetRepairItemStatus'
    },
    body: payload
  });

  const textResponse = await resp.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(textResponse, 'text/xml');

  const parsedNoteString = xmlDoc.getElementsByTagName('a:ItemStatus');
  const formattedResults = Array.from(parsedNoteString).map((item) => {
    return Array.from(item.childNodes)
      .map((node) => ({
        [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  });

  return formattedResults;
};
