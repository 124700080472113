import { Spin, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { getRepairLineNote } from 'api/GetRepairLineNote';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';

type Props = {
  record: Partial<RepairOrderToBePicked>;
};

export const RepairLineNotes: FC<Props> = ({ record }) => {
  const [isNoteLoading, setIsNoteLoading] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const [val, setVal] = useState<string>('');

  useEffect(() => {
    const fetchNote = async (): Promise<void> => {
      try {
        setIsNoteLoading(true);
        const resp = await getRepairLineNote({ repairLineRecId: +(record?.recId ?? 0) as number, dataAreaId: selectedDataAreaId });

        setVal(resp);

        setIsNoteLoading(false);
      } catch (error) {
        console.log(error);
        setIsNoteLoading(false);
      }
    };

    fetchNote();
  }, []);

  return (
    <Spin spinning={isNoteLoading}>
      <Typography.Text>Repair Line Notes</Typography.Text>
      <TextArea style={{ minHeight: 200 }} disabled value={val} />
    </Spin>
  );
};
