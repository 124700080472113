export const toRgb = (color: number[]): string => `rgb(${color.join(',')})`;

export const toRgba = (color: number[], alpha: number): string => `rgb(${color.join(',')}, ${alpha})`;

export const colors = {
  black: [0, 0, 0],
  culturedWhite: [240, 242, 245], // #f0f2f5
  royalBlueLight: [88, 103, 221], // #5867dd
  robinEggBlue: [39, 134, 250], // #2786fa
  raisinBlackLight: [31, 30, 46], // #1f1e2e
  raisinBlackDark: [24, 24, 35], // #181823
  romanSilver: [132, 133, 154], // #84859a
  illuminatingEmerald: [78, 147, 122], // #4e937a
  orangeWeb: [255, 165, 0], // #ffa500
  redSalsa: [249, 57, 67], // #f93943
  minimumYellow: [245, 247, 73], // #f5f749
  primary: '#4CA7FC',
  secondary: '#363636',
  success: {
    50: '#e1ffec',
    100: '#b8f7cf',
    200: '#8ef0b2',
    300: '#62ea94',
    400: '#38e476',
    500: '#20ca5d',
    600: '#159d47',
    700: '#0b7032',
    800: '#02431d',
    900: '#001804'
  },
  error: {
    50: '#ffe5e5',
    100: '#fdb7b8',
    200: '#f6898a',
    300: '#f15b5b',
    400: '#ec2e2d',
    500: '#d21513',
    600: '#a40e0e',
    700: '#760809',
    800: '#480304',
    900: '#1e0000'
  }
};
