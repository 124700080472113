import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { formatDate } from './CreateRepairOrder';

export const markRepairLineComplete = async ({
  line,
  dataAreaId,
  itemStatusId,
  repairLineNote,
  userName,
  conditionFinished,
  dispositionFinished,
  completedBinLocationId
}: {
  line: RepairOrderToBePicked;
  dataAreaId: string;
  itemStatusId: string;
  repairLineNote: string;
  conditionFinished: string;
  dispositionFinished: string;
  userName: string;
  completedBinLocationId: string;
}): Promise<string> => {
  const payload = `
       <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <MarkLineComplete xmlns="http://tempuri.org/">
      <repairLine xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair.Result" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <IsValidationEnforced xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">false</IsValidationEnforced>
        <DateStaged xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.dateStaged ? formatDate(line.dateStaged).toISOString() : ''}</DateStaged>
        <HoursWorked xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${+line.hoursWorked || 0}</HoursWorked>
        <InboundJournalId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair"></InboundJournalId>
        <ItemId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.itemId || ''}</ItemId>
        <ItemStatusId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.itemStatusId || ''}</ItemStatusId>
        <Picker xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.picker || ''}</Picker>
        <RecId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.recId || 0}</RecId>
        <RepairOrderId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairOrderId || ''}</RepairOrderId>
        <RepairPrice xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairPrice || 0.0}</RepairPrice>
        <RepairSerialNumber xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairSerialNumber || ''}</RepairSerialNumber>
        <RepairStageId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairStageId || ''}</RepairStageId>
        <RepairTechnicianId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairTechnicianId || ''}</RepairTechnicianId>
        <SLABusinessDays xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.sLABusinessDays || ''}</SLABusinessDays>
        <SalesOrderId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.salesOrderId || ''}</SalesOrderId>
        <d4p1:ConditionFinished>${conditionFinished || ''}</d4p1:ConditionFinished>
        <d4p1:ConfigId>${line.configId || ''}</d4p1:ConfigId>
        <d4p1:CustomerAccountNum>${line.customerAccountNum || ''}</d4p1:CustomerAccountNum>
        <d4p1:DateStaged>${line.dateStaged ? formatDate(line.dateStaged).toISOString() : ''}</d4p1:DateStaged>
        <d4p1:DispositionFinished>${dispositionFinished || ''}</d4p1:DispositionFinished>
        <d4p1:Error xmlns:d5p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model">
          <d5p1:IsValidationEnforced>false</d5p1:IsValidationEnforced>
          <d5p1:ErrorMessage i:nil="true" />
          <d5p1:IsSuccess>false</d5p1:IsSuccess>
          <d5p1:StackTrace i:nil="true" />
        </d4p1:Error>
        <d4p1:InventColorId>${line.inventColorId || ''}</d4p1:InventColorId>
        <d4p1:InventLocationId>${line.inventLocationId || ''}</d4p1:InventLocationId>
        <d4p1:InventLocationIdFinished>${line.inventLocationIdFinished || ''}</d4p1:InventLocationIdFinished>
        <d4p1:InventLocationIdInProcess>${line.inventLocationIdInProcess || ''}</d4p1:InventLocationIdInProcess>
        <d4p1:InventLocationIdStaging>${line.inventLocationIdStaging || ''}</d4p1:InventLocationIdStaging>
        <d4p1:InventSizeId>${line.inventSizeId || ''}</d4p1:InventSizeId>
        <d4p1:OrderDate>${line.orderDate ? formatDate(line.orderDate).toISOString() : ''}</d4p1:OrderDate>
        <d4p1:RepairLabId>${line.repairLabId || ''}</d4p1:RepairLabId>
        <d4p1:RepairLabInventLocationId>${line.repairLabInventLocationId || ''}</d4p1:RepairLabInventLocationId>
        <d4p1:RepairPrice>${Number(line.repairPrice) || 0.0}</d4p1:RepairPrice>
        <d4p1:RepairType>${line.repairType || ''}</d4p1:RepairType>
        <d4p1:WMSLocationId>${line.wMSLocationId || ''}</d4p1:WMSLocationId>
        <d4p1:WmsLocationIdFinished>${completedBinLocationId || ''}</d4p1:WmsLocationIdFinished>
        <d4p1:WmsLocationIdInProcess>${line.wmsLocationIdInProcess}</d4p1:WmsLocationIdInProcess>
        <d4p1:WmsLocationIdStaging>${line.wmsLocationIdStaging}</d4p1:WmsLocationIdStaging>
      </repairLine>
      <user xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:DataAreaId>${dataAreaId}</d4p1:DataAreaId>
        <d4p1:DefaultInventLocationId>${line.inventLocationId}</d4p1:DefaultInventLocationId>
        <d4p1:DefaultPrinterName></d4p1:DefaultPrinterName>
        <d4p1:Groups xmlns:d5p1="http://schemas.microsoft.com/2003/10/Serialization/Arrays" />
        <d4p1:Password></d4p1:Password>
        <d4p1:UserName>${userName}</d4p1:UserName>
      </user>
      <repairStageId>Complete</repairStageId>
      <itemStatusId>${itemStatusId}</itemStatusId>
      ${repairLineNote !== '' || (!repairLineNote && `<newNote>${repairLineNote}</newNote>`)}
      <dataAreaId>${dataAreaId}</dataAreaId>
    </MarkLineComplete>
  </s:Body>
</s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/MarkLineComplete'
    },
    body: payload
  });

  const textResponse = await resp.text();

  return textResponse;
};
