import { Col, Row } from 'antd';
import { FaultCodeSelect } from 'components/atoms/FaultCodeSelect';
import { FieldInputTextArea } from 'components/atoms/FieldInputTextArea';
import { RepairLineNotes } from 'components/atoms/RepairLineNotes';
import { RepairStageSelect } from 'components/atoms/RepairStageSelect';
import { ResolutionCodeSelect } from 'components/atoms/ResolutionCodeSelect';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import React, { FC } from 'react';

type Props = {
  isLineItem?: boolean;
  record: Partial<RepairOrderToBePicked>;
};

const LineItemTechFormComponent: FC<Props> = ({ isLineItem, record }) => {
  return (
    <Row gutter={[20, 10]}>
      {!isLineItem && (
        <Col span={isLineItem ? 12 : 8}>
          <RepairStageSelect />
        </Col>
      )}
      <Col span={isLineItem ? 12 : 8}>
        <ResolutionCodeSelect />
      </Col>
      <Col span={isLineItem ? 12 : 8}>
        <FaultCodeSelect />
      </Col>
      <Col span={8}>
        <FieldInputTextArea style={{ height: 75 }} fieldName="faultNotes" label="Fault Code Notes" placeholder="Fault Code Notes" />
      </Col>
      <Col span={8}>
        <FieldInputTextArea style={{ height: 75 }} fieldName="resolutionNotes" label="Resolution Code Notes" placeholder="Resolution Code Notes" />
      </Col>
      <Col span={8}>
        <FieldInputTextArea style={{ height: 75 }} fieldName="newRepairLineNote" label="New Repair Line Note" />
      </Col>
      <Col span={24}>
        <RepairLineNotes record={record} />
      </Col>
    </Row>
  );
};

export const LineItemTechForm = React.memo(LineItemTechFormComponent);
