import { Select } from 'antd';
import { parseSoapResponse } from 'api/GetLookupTables';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  isStage?: boolean;
  setForm?: React.Dispatch<
    React.SetStateAction<{
      repairStageId: string;
      technicianId: string;
    }>
  >;
};

const RepairStageSelectComponent: FC<Props> = ({ isStage = false, setForm }): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairStages');

      const formattedData = test123?.map((item) => item?.['b:RepairStageId']);

      setData(formattedData);
    };

    fetchData();
  }, [lookupTables]);
  if (isStage)
    return (
      <Select
        onChange={(e) => setForm?.((prev) => ({ ...prev, repairStageId: e }))}
        style={{ width: '100%' }}
        options={data?.map((item) => ({ label: item, value: item }))}
        placeholder="Select Repair Stage"
      />
    );

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="status" label="Repair Stage" />;
};

export const RepairStageSelect = React.memo(RepairStageSelectComponent);
