import { CheckCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Input, message, Modal, Space, Spin, Typography } from 'antd';
import { assignSONumberToLines } from 'api/AssignSONumberToLines';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { RepairOrderToAssignSoNumber } from 'components/molecules/SOBillingTable';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRefetchLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { SizedLoader } from './SizedLoader';
type Props = {
  selectedRows: RepairOrderToAssignSoNumber[];
  setSelectedRows: React.Dispatch<React.SetStateAction<RepairOrderToAssignSoNumber[]>>;
};

export const AssignSalesIdToLines: FC<Props> = ({ selectedRows, setSelectedRows }) => {
  const [visible, setVisible] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [val, setVal] = useState('');

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true);

      await assignSONumberToLines({
        lines: selectedRows,
        dataAreaId: selectedDataAreaId,
        userName: user?.name ?? '',
        soNumber: val
      });

      message.success(`Successfully assigned SO number ${val}`);
      dispatch(setRefetchLines(true));
      setLoading(false);
      setVal('');
      setVisible(false);
      setSelectedRows([]);
    } catch (err) {
      console.log(err);
      setLoading(false);
      message.error('There was an error processing your request');
    }
  };

  return (
    <>
      <Button
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        style={!selectedRows.length ? undefined : { backgroundColor: 'green', color: 'white', opacity: hover ? 0.8 : 1 }}
        disabled={!selectedRows.length}
        onClick={() => setVisible(true)}>
        Assign SO Number ({selectedRows.length})
        <CheckCircleOutlined />
      </Button>
      <Modal
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
        okText="Submit"
        onOk={handleSubmit}
        width={425}
        closable={false}
        destroyOnClose
        open={visible}
        onCancel={() => setVisible(false)}>
        <ColorCard color={toRgba(colors.illuminatingEmerald, 0.4)} title={'Sales Order Information'}>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Spin spinning={loading} indicator={<SizedLoader medium />}>
              <Typography.Text>Assign SO Number</Typography.Text>
              <Input value={val} onChange={(e) => setVal(e.target.value)} />
            </Spin>
          </Space>
        </ColorCard>
      </Modal>
    </>
  );
};
