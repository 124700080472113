import { toCamelCase } from './GetLookupTables';

export const getAllLinesByTechQ = async (dataAreaId: string): Promise<any> => {
  const url = 'https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api';

  try {
    const resp = await fetch(url, {
      method: 'POST',
      cache: 'force-cache',
      headers: {
        'Content-Type': 'text/xml',
        SOAPAction: 'http://tempuri.org/IRepairModule/GetAllRepairLinesAssignedByTech'
      },
      body: `
                <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tns:GetAllRepairLinesAssignedByTech>
        <tns:techName></tns:techName>
        <tns:dataAreaId>${dataAreaId}</tns:dataAreaId>
      </tns:GetAllRepairLinesAssignedByTech>
    </soapenv:Body>
  </soapenv:Envelope>
              `
    });

    const textResponse = await resp.text();

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(textResponse, 'text/xml');
    const parsedXml = xmlDoc.getElementsByTagName('a:TechQueueResult');
    const formattedResults = Array.from(parsedXml).map((item) => {
      return Array.from(item.childNodes)
        .map((node) => ({
          [node.nodeName.includes(':') ? toCamelCase(node.nodeName.split(':')[1]) : toCamelCase(node.nodeName ?? '')]: node.textContent
        }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    });

    return formattedResults;
  } catch (error) {
    console.log(error);

    return [];
  }
};
