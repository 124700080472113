import { RepairOrderToAssignSoNumber } from 'components/molecules/SOBillingTable';
import { formatDate } from './CreateRepairOrder';

export const assignSONumberToLines = async ({
  lines,
  dataAreaId,
  userName,
  soNumber
}: {
  lines: RepairOrderToAssignSoNumber[];
  dataAreaId: string;
  userName: string;
  soNumber: string;
}): Promise<string> => {
  const payload = `
       <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Body>
    <SetRepairLinesWithSalesId xmlns="http://tempuri.org/">
      <lines xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair.Result" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
      ${lines
        .map((line) => {
          return `<d4p1:RepairLineAwaitingSalesIdResult xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair.Result" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <IsValidationEnforced xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">false</IsValidationEnforced>
        <InboundJournalId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair"></InboundJournalId>
        <ItemId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.itemId || ''}</ItemId>
        <ItemStatusId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.itemStatusId || ''}</ItemStatusId>
        <RecId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.recId || 0}</RecId>
        <RepairOrderId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairOrderId || ''}</RepairOrderId>
        <RepairPrice xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairPrice || 0.0}</RepairPrice>
        <RepairSerialNumber xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairSerialNumber || ''}</RepairSerialNumber>
        <RepairStageId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairStageId || ''}</RepairStageId>
        <RepairTechnicianId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairTechnicianId || ''}</RepairTechnicianId>
        <SalesOrderId xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${soNumber}</SalesOrderId>
        <RepairAdmin xmlns="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair">${line.repairAdmin || ''}</RepairAdmin>
        <d4p1:CustomerAccountNum>${line.customerAccountNum || ''}</d4p1:CustomerAccountNum>
        <d4p1:Error xmlns:d5p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model">
          <d5p1:IsValidationEnforced>false</d5p1:IsValidationEnforced>
          <d5p1:ErrorMessage i:nil="true" />
          <d5p1:IsSuccess>false</d5p1:IsSuccess>
          <d5p1:StackTrace i:nil="true" />
        </d4p1:Error>
        <d4p1:OrderDate>${line.orderDate ? formatDate(line.orderDate).toISOString() : ''}</d4p1:OrderDate>
        <d4p1:RepairLabId>${line.repairLabId || ''}</d4p1:RepairLabId>
        <d4p1:SalesOrderId>${soNumber}</d4p1:SalesOrderId>
        <d4p1:RepairPrice>${Number(line.repairPrice) || 0.0}</d4p1:RepairPrice>
        <d4p1:RepairAdmin>${line.repairAdmin || ''}</d4p1:RepairAdmin>
      </d4p1:RepairLineAwaitingSalesIdResult>`;
        })
        .join('')}
      </lines>
      <user xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <d4p1:IsValidationEnforced>false</d4p1:IsValidationEnforced>
        <d4p1:DataAreaId>${dataAreaId}</d4p1:DataAreaId>
        <d4p1:DefaultInventLocationId></d4p1:DefaultInventLocationId>
        <d4p1:DefaultPrinterName></d4p1:DefaultPrinterName>
        <d4p1:Groups xmlns:d5p1="http://schemas.microsoft.com/2003/10/Serialization/Arrays" />
        <d4p1:Password></d4p1:Password>
        <d4p1:UserName>${userName}</d4p1:UserName>
      </user>
      <dataAreaId>${dataAreaId}</dataAreaId>
    </SetRepairLinesWithSalesId>
  </s:Body>
</s:Envelope>`;

  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/SetRepairLinesWithSalesId'
    },
    body: payload
  });

  const textResponse = await resp.text();

  return textResponse;
};
