import { CheckCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, message, Modal, Select, Space, Spin, Typography } from 'antd';
import { markRepairLineComplete } from 'api/MarkLineComplete';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRefetchLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { BinSelect } from './BinSelect';
import { ItemStatusByCustomerSelect } from './ItemStatusByCustomerSelect';
import { SizedLoader } from './SizedLoader';

type Props = {
  record: RepairOrderToBePicked;
  repairLineNote: string;
  setFirstModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CompleteItemModal: FC<Props> = ({ record, repairLineNote, setFirstModalVisible }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const { user } = useAuth0();
  const userPrinters = (user as any)['https://acuity.mdsiinc.com/user/user_metadata'].assigned_printers;

  const options = userPrinters?.map((printer: any, idx: number) => ({ label: printer.Name, value: printer.Address, key: idx }));

  const defaultPrinter = userPrinters?.find((printer: any) => printer.IsDefault);

  const dispatch = useDispatch();
  const [form, setForm] = useState<{
    itemStatusId: string;
    conditionFinished: string;
    dispositionFinished: string;
    binLocationId: string;
    printer: string;
    port: number;
  }>({
    itemStatusId: '',
    conditionFinished: '',
    dispositionFinished: '',
    binLocationId: record.wmsLocationIdFinished || '',
    printer: defaultPrinter?.Address || '',
    port: defaultPrinter?.Port || 0
  });

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      await markRepairLineComplete({
        line: record,
        dataAreaId: selectedDataAreaId,
        itemStatusId: form.itemStatusId,
        conditionFinished: form.conditionFinished,
        dispositionFinished: form.dispositionFinished,
        completedBinLocationId: form.binLocationId,
        repairLineNote: repairLineNote,
        userName: user?.name ?? ''
      });

      const dataToSend: RepairOrderToBePicked = {
        ...record,
        conditionFinished: form.conditionFinished,
        dispositionFinished: form.dispositionFinished
      };

      await fetch('https://feline-intent-polecat.ngrok-free.app/sendPrintJob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ipAddress: form.printer,
          port: Number(form.port),
          printDetails: [dataToSend],
          isRepair: true
        })
      });

      dispatch(setRefetchLines(true));
      setVisible(false);
      setFirstModalVisible(false);
      message.success('Item successfully completed');
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('There was an error processing your request');
      setLoading(false);
    }
  };

  return (
    <>
      <Button style={{ background: 'green', color: 'white' }} onClick={() => setVisible(true)}>
        Complete Item <CheckCircleOutlined />
      </Button>

      <Modal
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
        onOk={handleSubmit}
        width={425}
        closable={false}
        destroyOnClose
        okText="Submit Item"
        open={visible}
        onCancel={() => setVisible(false)}>
        <ColorCard color={toRgba(colors.illuminatingEmerald, 0.4)} title={'Complete Item'}>
          <Spin spinning={loading} indicator={<SizedLoader medium />}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <div>
                <Typography.Text>Item Status</Typography.Text>
                <ItemStatusByCustomerSelect record={record} setForm={setForm} />
              </div>
              <div>
                <Typography.Text>Bin Location</Typography.Text>
                <BinSelect record={record} formVals={form} setForm={setForm} />
              </div>
              <div>
                <Typography.Text>Printer</Typography.Text>
                <Select
                  onSelect={(e): void => {
                    const foundPrinter = userPrinters.find((item: any) => item.Address === e);

                    setForm((prev) => ({ ...prev, printer: e, port: foundPrinter?.Port }));
                  }}
                  value={form.printer}
                  placeholder="Select Printer"
                  options={options}
                  style={{ width: '100%' }}
                />
              </div>
            </Space>
          </Spin>
        </ColorCard>
      </Modal>
    </>
  );
};
