import { Select } from 'antd';
import { parseSoapResponse } from 'api/GetLookupTables';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  setForm?: React.Dispatch<
    React.SetStateAction<{
      pickerId: string;
    }>
  >;
  isPicking?: boolean;
};

const AdminSelectComponent: FC<Props> = ({ setForm, isPicking = false }): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'Employees');

      const formattedData = test123?.map((item) => item?.['b:EmployeeId']);

      setData(formattedData);
    };

    fetchData();
  }, [lookupTables]);

  if (isPicking)
    return (
      <Select
        showSearch
        allowClear
        onChange={(e) => setForm?.((prev: any) => ({ ...prev, pickerId: e }))}
        options={data?.map((item) => ({ label: item, value: item }))}
        placeholder="Picker"
        style={{ width: '100%' }}
      />
    );

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="RepairAdmin" label="Repair Admin" placeholder="Repair Admin" />;
};

export const AdminSelect = React.memo(AdminSelectComponent);
